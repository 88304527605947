import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Sidebar from "../../Components/Sidebar";
import NavigationButtons from "../../Components/NavigationButtons";
import { connect } from "react-redux";
import {
  addNewCustomer,
  deleteCustomer,
  loadAllCustomers
} from "../../Redux/actions/customer";
import {
  EMAIL_REGEX,
  DATE_REGEX,
  DEFAULT_IMAGE,
  PHONE_REGEX,
  IMAGE_SIZE_LIMIT,
  ALLOWED_IMAGE_TYPES
} from "../../constants";
import { getHumanizeTime, checkDateFormat } from "../../Utils/misc.utils";
import { showNotification } from "../../Utils/misc.utils";
import DateSelector from "../../Components/ReusableComponents/Datepicker";
import SmallLoader from "../../Components/Common/small.loader";
import moment from "moment";
import { isEqual } from "lodash";
import InputMask from "react-input-mask";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { boolean } from "yup";

const mapStateToProps = state => {
  return { 
    username: state?.User?.data?.username,
    customers: state.Customers.customers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNewCustomer: addNewCustomer(dispatch),
    loadAllCustomers: loadAllCustomers(dispatch),
    deleteCustomer: deleteCustomer(dispatch),
    dispatch: dispatch
  };
};

const Customers = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        saveCustomerLoading: false,
        modalShow: false,
        sortParams: { sortBy: "fullName", order: "ascending" },
        search: "",
        deleteLoading: false,
        initialCustomerState: {
          name: "",
          dob: null,
          email: "",
          phone: "",
          address: "",
          imageUrl: "",
          allergy: ""
        },
        initialValidInputState: {
          name: true,
          dob: true,
          email: true,
          phone: true,
          address: true,
          imageUrl: true,
          allergy: true
        },
        addCustomer: {
          name: "",
          dob: null,
          email: "",
          phone: "",
          address: "",
          imageUrl: "",
          allergy: ""
        },
        validInput: {
          name: true,
          dob: true,
          email: true,
          phone: true,
          address: true,
          imageUrl: true,
          allergy: true
        },
        requiredInput: {
          name: true,
          dob: false,
          email: false,
          phone: false,
          address: false,
          imageUrl: false,
          allergy: false
        }
      };
    }

    sortingOptions = [
      { key: "fullName", value: "Name" },
      { key: "lastVisit", value: "Last Visited" },
      { key: "phone", value: "Phone" },
      { key: "email", value: "Email" },
      { key: "dob", value: "DOB" }
    ];

    changeSortParams = option => {
      const { sortParams } = this.state;
      if (sortParams.sortBy === option && sortParams.order === "ascending") {
        this.setState({ sortParams: { sortBy: option, order: "descending" } });
        return;
      }
      this.setState({ sortParams: { sortBy: option, order: "ascending" } });
    };

    renderTableHeadings = () => {
      const { sortParams } = this.state;
      return this.sortingOptions.map((option, index) => {
        return (
          <th key={index}>
            <span className="d-flex align-items-center">
              {option.value}
              <i
                className={
                  option.key === sortParams.sortBy &&
                  sortParams.order === "descending"
                    ? "icon-caret-up"
                    : "icon-caret-down"
                }
                onClick={() => this.changeSortParams(option.key)}
              ></i>
            </span>
          </th>
        );
      });
    };

    componentDidUpdate(prevProps, prevState) {
      const { sortParams } = this.state;
      if (
        sortParams.sortBy !== prevState.sortParams.sortBy ||
        sortParams.order !== prevState.sortParams.order
      ) {
        this.props.loadAllCustomers(sortParams);
      }
    }

    setModalShow = val => this.setState({ modalShow: val });

    handleClose = () => {
      this.resetAddCustomer();
      this.setModalShow(false);
    };

    invokeClose = () => {
      const { addCustomer, initialCustomerState } = this.state;
      if (!isEqual(addCustomer, initialCustomerState)) {
        const response = window.confirm(
          "You have unsaved changes that will be lost."
        );
        if (response) {
          this.handleClose();
        }
      } else {
        this.handleClose();
      }
    };

    resetAddCustomer = () => {
      this.setState(prevState => ({
        addCustomer: prevState.initialCustomerState,
        validInput: prevState.initialValidInputState
      }));
    };

    setCustomerField = (field, val) =>
      this.setState(prevState => ({
        addCustomer: { ...prevState.addCustomer, [field]: val }
      }));

    modifySearch = val => this.setState({ search: val });

    setCustomerImage = async e => {
      if (e.target.files) {
        const file = e?.target?.files[0];
        if (file?.size < IMAGE_SIZE_LIMIT) {
          if (ALLOWED_IMAGE_TYPES.includes(file?.type)) {
            this.setState({
              addCustomer: {
                ...this.state.addCustomer,
                imageUrl: URL.createObjectURL(file)
              }
            });
          } else {
            alert("Allowed image types are jpg, png and svg");
          }
        } else {
          alert(`Image Size Limit Exceeded (10 MB)`);
        }
      }
    };

    filterLoadedCustomers = () => {
      const { customers } = this.props;
      const { search } = this.state;
      if (search.trim() === "") {
        return customers;
      }
      const filteredCustomers = [];
      const filterKeys = ["name", "dob", "email", "phone", "lastVisited"];
      customers.map(customer => {
        let match = false;
        filterKeys.map(key => {
          if (customer[key]) {
            let value = customer[key].toLowerCase();
            if (key === "dob" || key === "lastVisited") {
              value = getHumanizeTime(customer[key]).toLowerCase();
            }
            if (value.includes(search.toLowerCase())) {
              match = true;
            }
          }
        });
        if (match) {
          filteredCustomers.push(customer);
        }
      });
      return filteredCustomers;
    };

    canSaveNewCustomer = () => {
      const { validInput, requiredInput, addCustomer } = this.state;
      let disabled = false;
      Object.keys(addCustomer).forEach(key => {
        if (requiredInput[key] && (!validInput[key] || !addCustomer[key])) {
          disabled = true;
        }
      });
      return disabled;
    };

    updateValidInput = (key, val) => {
      this.setState(prevState => ({
        validInput: { ...prevState.validInput, [key]: val }
      }));
    };

    checkValidInput = (key, val) => {
      if (key === "email") {
        this.updateValidInput(
          "email",
          !(val && !EMAIL_REGEX.test(val?.toLowerCase()))
        );
      } else if (key === "phone") {
        this.updateValidInput("phone", !(val && !PHONE_REGEX.test(val)));
      } else if (key === "dob") {
        this.updateValidInput(
          "dob",
          !(
            val &&
            (!DATE_REGEX.test(moment(val).format("DD/MM/YYYY")) ||
              checkDateFormat(moment(val).format("DD/MM/YYYY")))
          )
        );
      }
    };

    // ***** Abstract it as a helper function

    checkValidFormInputs = () => {
      const { validInput, addCustomer, requiredInput } = this.state;
      const modify = {};
      let invalidInput = false;
      Object.keys(addCustomer).map(key => {
        if (requiredInput[key] && (!validInput[key] || !addCustomer[key])) {
          modify[key] = false;
          invalidInput = true;
        }
        if (
          key === "email" &&
          addCustomer[key] &&
          !EMAIL_REGEX.test(addCustomer[key]?.toLowerCase())
        ) {
          modify[key] = false;
          invalidInput = true;
        }
        if (
          key === "phone" &&
          addCustomer[key] &&
          !PHONE_REGEX.test(addCustomer[key]?.toLowerCase())
        ) {
          modify[key] = false;
          invalidInput = true;
        }
        if (
          key === "dob" &&
          addCustomer[key] &&
          (!DATE_REGEX.test(moment(addCustomer[key]).format("DD/MM/YYYY")) ||
            checkDateFormat(moment(addCustomer[key]).format("DD/MM/YYYY")))
        ) {
          modify[key] = false;
          invalidInput = true;
        }
      });
      this.setState({ validInput: { ...validInput, ...modify } });
      return invalidInput;
    };

    saveNewCustomer = async () => {
      // ***** This will be updated once db is setup *****
      const { addCustomer } = this.state;
      if (this.checkValidFormInputs()) {
        return;
      }
      if (this.state.saveCustomerLoading) {
        showNotification(
          "info",
          "Add Customer In Progress",
          "Please wait until the call is finished"
        );
        return;
      }
      this.setState({ saveCustomerLoading: true });
      const err = await this.props.addNewCustomer(addCustomer);
      this.setState({ saveCustomerLoading: false });
      if (err) {
        showNotification("error", "Add Customer Error", err);
      } else {
        showNotification(
          "success",
          "Add Customer Success",
          "Added a new customer successfully"
        );
        this.resetAddCustomer();
        this.handleClose();
      }
    };

    componentDidMount() {
      const { sortParams } = this.state;
      this.props.loadAllCustomers(sortParams);
    }

    deleteCustomer = async customerId => {
      this.setState({ deleteLoading: true });
      const err = await this.props.deleteCustomer(customerId);
      this.setState({ deleteLoading: false });
      if (err) {
        showNotification("error", "Delete Customer Error", err);
      } else {
        document.body.click();
        showNotification(
          "success",
          "Delete Customer Success",
          "Customer deleted successfully"
        );
      }
    };

    renderModal = () => {
      const {
        modalShow,
        addCustomer,
        validInput,
        saveCustomerLoading
      } = this.state;
      return (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="app-modal"
          scrollable
          show={modalShow}
          onHide={this.invokeClose}
          backdrop="static"
        >
          <Modal.Body>
            <Form className="w-100">
              <div className="customer-profile customer-incomplete">
                <div className="modal-canvas">
                  <div className="cp-inner d-flex flex-column flex-md-row justify-contnent-md-between align-items-md-center mb-0">
                    <div className="user-profile user-profile-lg d-flex align-items-center">
                      <div className="user-img-canvas">
                        <label className="customer-img-upload bg-primary">
                          {addCustomer.imageUrl ? (
                            <img src={addCustomer.imageUrl} alt="User Img" />
                          ) : (
                            <React.Fragment>
                              <input
                                type="file"
                                onChange={this.setCustomerImage}
                                accept="image/*"
                              />
                              <i className="icon-edit"></i>
                            </React.Fragment>
                          )}
                          {/* *****  file upload to be added here once db implemented***** */}
                        </label>
                        {addCustomer.imageUrl && (
                          <span
                            className="user-cross"
                            onClick={() =>
                              this.setState({
                                addCustomer: {
                                  ...this.state.addCustomer,
                                  imageUrl: ""
                                }
                              })
                            }
                          >
                            <i className="icon-cross"></i>
                          </span>
                        )}
                      </div>
                      <div className="user-detail w-100">
                        <Form.Group className="modal-group modalGroup-name mb-0">
                          <Form.Control
                            value={addCustomer.name}
                            onChange={e => {
                              this.setCustomerField("name", e.target.value);
                              this.checkValidInput("name", e.target.value);
                            }}
                            type="text"
                            placeholder="Jane Doe"
                            isInvalid={!validInput.name}
                            onBlur={e =>
                              this.checkValidInput("name", e.target.value)
                            }
                          />
                        </Form.Group>
                        <div className="d-flex align-items-center">
                          <div className="exclamation-mark">
                            <i className="icon-warning"></i>
                          </div>
                          <Form.Group className="modal-group mb-0">
                            <Form.Control
                              type="text"
                              placeholder="Special treatment needs"
                              value={addCustomer.allergy}
                              onChange={e => {
                                this.setCustomerField(
                                  "allergy",
                                  e.target.value
                                );
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="profile-top-col">
                      <ul className="profile-list">
                        <li>
                          <i className="icon-calendar text-primary"></i>
                          <DateSelector
                            value={addCustomer.dob}
                            handleChange={date => {
                              this.setCustomerField("dob", date);
                              this.checkValidInput("dob", addCustomer.dob);
                            }}
                            onBlur={field =>
                              this.checkValidInput(field, addCustomer.dob)
                            }
                          />
                        </li>
                        <li>
                          <i className="icon-mail text-primary"></i>
                          <Form.Group className="modal-group modalGroup-list mb-0">
                            <Form.Control
                              value={addCustomer.email}
                              onChange={e => {
                                this.setCustomerField("email", e.target.value);
                                this.checkValidInput("email", e.target.value);
                              }}
                              type="text"
                              placeholder="Email"
                              isInvalid={!validInput.email}
                              onBlur={e =>
                                this.checkValidInput("email", e.target.value)
                              }
                            />
                          </Form.Group>
                        </li>
                        <li>
                          <i className="icon-phone text-primary"></i>
                          <Form.Group className="modal-group modalGroup-list mb-0">
                            <InputMask
                              value={addCustomer.phone}
                              onChange={e => {
                                this.setCustomerField("phone", e.target.value);
                                this.checkValidInput("phone", e.target.value);
                              }}
                              onBlur={e =>
                                this.checkValidInput("phone", e.target.value)
                              }
                              type="text"
                              placeholder="Phone"
                              mask="(999) 999-9999"
                              maskChar="_"
                              className={
                                !validInput.phone
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                          </Form.Group>
                        </li>
                        <li>
                          <i className="icon-map-marker text-primary"></i>
                          <Form.Group className="modal-group modalGroup-list mb-0">
                            <Form.Control
                              value={addCustomer.address}
                              onChange={e => {
                                this.setCustomerField(
                                  "address",
                                  e.target.value
                                );
                              }}
                              type="text"
                              placeholder="Address"
                            />
                          </Form.Group>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={this.saveNewCustomer}
                    className="btn addCustomer-btn addCustomer-btn-complete"
                    disabled={this.canSaveNewCustomer() || saveCustomerLoading}
                  >
                    {saveCustomerLoading ? "Adding" : "Add Customer"}
                    {saveCustomerLoading && <SmallLoader />}
                  </Button>
                </div>
                <Button
                  onClick={this.invokeClose}
                  className="btn close-btn close-circle"
                >
                  <i className="icon-cross"></i>
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      );
    };

    renderCustomerInfos = () => {
      // ***** search related filter here *****
      const filteredCustomers = this.filterLoadedCustomers();
      return filteredCustomers.map((customer, index) => {
        return (
          <tr
            key={index}
            className="table-row"
            onClick={() => this.props.history.push("/profile/" + customer.id)}
          >
            <td>
              <div className="user-profile user-profile-customer d-flex align-items-center">
                <div className="user-img-canvas">
                  <div className="user-img">
                    <img
                      src={
                        customer.imageUrl ? customer.imageUrl : DEFAULT_IMAGE
                      }
                      alt="User Img"
                    />
                  </div>
                  {customer.allergy && (
                    <div className="exclamation-mark exclamation-mark-sm bg-primary">
                      <i className="icon-warning"></i>
                    </div>
                  )}

                  {/* <div className="exclamation-mark exclamation-mark-sm">
                    <i className="icon-warning"></i>
                  </div> */}
                </div>
                <div className="user-detail">
                  {this.props.username != "shannonboddie" ? (
                    <h6 className="user-name">{customer.name.slice(0, 3).padEnd(10, '*')}</h6>
                  ) : (
                    <h6 className="user-name">{customer.name}</h6>
                  )}
                </div>
              </div>
            </td>
            <td>
              <span className="font-weight-bold">
                {this.props.username != "shannonboddie" ? (getHumanizeTime("1/1/1800")) : (customer.lastVisited ? getHumanizeTime(customer.lastVisited) : "N/A")}
              </span>
            </td>
            <td>
            {this.props.username != "shannonboddie" ? (<div>{customer.phone ? customer.phone.slice(0, 5).padEnd(10, '*') : "N/A"}</div>) : (<div>{customer.phone || "N/A"}</div>)}
            </td>
            <td>
                {this.props.username != "shannonboddie" ? (<div>{customer.email ? customer.email.slice(0, 3).padEnd(10, '*') : "N/A"}</div>) : (<div>{customer.email || "N/A"}</div>)}
            </td>
            <td>{this.props.username != "shannonboddie" ? (getHumanizeTime("1/1/1800")) : (customer.dob ? getHumanizeTime(customer.dob) : "N/A")}</td>
            <td onClick={e => e.stopPropagation()}>
              <OverlayTrigger
                trigger="click"
                placement="left"
                rootClose
                rootCloseEvent="click"
                overlay={
                  <Popover className="popover-custom">
                    <Popover.Title>
                      <h4>Are you sure you want to delete this user?</h4>
                    </Popover.Title>
                    <Popover.Content>
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          this.deleteCustomer(customer.id);
                        }}
                        disabled={this.state.deleteLoading || this.props.username != "shannonboddie"}
                      >
                        {this.state.deleteLoading ? (
                          <React.Fragment>
                            Deleting
                            <SmallLoader />
                          </React.Fragment>
                        ) : (
                          "Delete"
                        )}
                      </Button>
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="remove-customer">
                  <i className="icon-plus"></i>
                </div>
              </OverlayTrigger>
            </td>
          </tr>
        );
      });
    };

    render() {
      console.log(this.state.deleteLoading);
      const { customers } = this.props;
      const { search } = this.state;
      return (
        <div className="off-canvas-container">
          <Sidebar />
          <div className="main-content customer-content">
            <NavigationButtons />
            <div className="row">
              <div className="col-12 col-xl-11">
                <div className="pl-lg-3">
                  <h2 className="app-heading mb-1">
                    Customers
                    <small className="font-weight-bold ml-2">
                      ({customers?.length || 0})
                    </small>
                  </h2>
                  <p className="app-paragraph">
                    All customers that have been treated at Flawless Tacoma.
                  </p>
                  <div className="form-group search-group mt-4">
                    <input
                      type="search"
                      className="form-control form-field"
                      placeholder="Search"
                      value={search}
                      onChange={e => this.modifySearch(e.target.value)} // ***** Will be updated with a backend call right now searching on the loaded ones *****
                    />
                    <i className="icon-search"></i>
                  </div>
                </div>
                <div className="table-canvas">
                  <Table responsive className="app-table customer-table">
                    <thead>
                      <tr>{this.renderTableHeadings()}</tr>
                    </thead>
                    <tbody>{this.renderCustomerInfos()}</tbody>
                  </Table>
                </div>
              </div>
            </div>
            <button
              onClick={() => this.setModalShow(true)}
              className="plus-circle plus-fixed bg-primary"
            >
              <i className="icon-plus"></i>
            </button>
            {this.renderModal()}
          </div>
        </div>
      );
    }
  }
);

export default withRouter(Customers);

Customers.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func,
  addNewCustomer: PropTypes.func
};

Customers.defaultProps = {
  customers: [],
  dispatch: () => {},
  addNewCustomer: () => {}
  // customers: [
  //   {
  //     name: "Jane Doe",
  //     dob: "12/10/2030",
  //     email: "jane.doe@random.com",
  //     phone: "0900-7869",
  //     address: "random house random street random random",
  //     imageUrl: "images/customer-10.png",
  //     allergy: "chrona virus",
  //     lastVisited: "14/10/2020"
  //     id: 1234
  //   },
  //   {
  //     name: "John Doe",
  //     dob: "12/10/2069",
  //     email: "john.doe@random.com",
  //     phone: "0900-786969",
  //     address: "amrika",
  //     imageUrl: "images/customer-2.png",
  //     allergy: "garmi",
  //     lastVisited: "10/10/2069"
  //     id: 1234
  //   }
  // ]
};
