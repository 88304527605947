import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UserModal from "../UserModal";
import { DEFAULT_IMAGE } from "../../constants";
import { updateUserInfo } from "../../Redux/actions/user";
import { logout } from "../../Redux/actions/login";

const mapStateToProps = state => {
  return {
    showSidebar: state.Sidebar.showSidebar,
    name: state?.User?.data?.fullName,
    avatar: state?.User?.data?.avatar
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
    updateUserInfo: updateUserInfo(dispatch),
    logout: logout
  };
};

const Sidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showModal: false
      };
    }

    setShowModal = val => this.setState({ showModal: val });
    handleClose = () => {
      this.setShowModal(false);
    };

    logMeOut = () => {
      this.props
        .logout()
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.props.history.push("/login");
        });
    };

    render() {
      console.log(this.props.avatar);
      const { showSidebar } = this.props;
      const { showModal } = this.state;
      return (
        <div
          className={
            showSidebar
              ? "sidebar bg-primary  sidebar-show"
              : "sidebar bg-primary"
          }
        >
          <div className="sidebar-canvas scrollbar-set bg-primary">
            <div
              className="user-profile d-flex"
              onClick={() => {
                this.setShowModal(true);
              }}
            >
              <div className="user-img-canvas">
                <a className="digit-box">
                  <i className="icon-edit"></i>
                </a>
                <div className="user-img">
                  <img
                    src={this.props.avatar ? this.props.avatar : DEFAULT_IMAGE}
                    alt="User Img"
                  />
                </div>
              </div>
              <div className="user-detail position-relative">
                <a className="digit-box">
                  <i className="icon-edit"></i>
                </a>
                <h6 className="user-name">
                  <span className="d-flex">Hello,</span>
                  {this.props.name}
                </h6>
              </div>
            </div>
            <ul className="sidebar-list">
              <li>
                <NavLink to="/customer">
                  <i className="icon-user"></i>
                  Customers
                </NavLink>
              </li>
              <li>
                <NavLink to="/settings">
                  <i className="icon-setting"></i>
                  Settings
                </NavLink>
              </li>
              <li onClick={this.logMeOut}>
                <a>
                  <i className="icon-logout"></i>
                  Logout
                </a>
              </li>
            </ul>
          </div>
          {showModal ? (
            <UserModal
              name={this.props.name}
              avatar={this.props.avatar}
              handleClose={this.handleClose}
              show={showModal}
              updateUserInfo={this.props.updateUserInfo}
            />
          ) : null}
        </div>
      );
    }
  }
);

Sidebar.propTypes = {
  showSidebar: PropTypes.bool,
  name: PropTypes.string,
  avatar: PropTypes.string,
  updateUserInfo: PropTypes.func
};
Sidebar.defaultProps = {
  showSidebar: false,
  name: "",
  avatar: "",
  updateUserInfo: () => {}
};

export default withRouter(Sidebar);
