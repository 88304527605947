import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Sidebar from "../../Components/Sidebar";
import NavigationButtons from "../../Components/NavigationButtons";
import ProfileInfo from "../../Components/ProfileInfo";
import * as ReusableComponents from "../../Components/ReusableComponents/styles";
import DateSelector from "../../Components/ReusableComponents/Datepicker";
import {
  loadUserProfileInfo,
  clearUserProfileInfo
} from "../../Redux/actions/profileInfo";

import { loadFormOptions } from "../../Redux/actions/formOptions";
import {
  addNewTreatment,
  loadAllTreatments,
  addNewForm,
  addNewImage,
  deleteImage,
  deleteForm,
  deleteTreatment
} from "../../Redux/actions/treatment";
import {
  ALLOWED_IMAGE_TYPES,
  DEFAULT_IMAGE,
  IMAGE_SIZE_LIMIT
} from "../../constants";
import { connect } from "react-redux";

import { getHumanizeTime, showNotification } from "../../Utils/misc.utils";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import SmallLoader from "../../Components/Common/small.loader";
import { isEqual } from "lodash";

const mapStateToProps = state => {
  return {
    customer: { ...state.ProfileInfo.profileInfo },
    treatments: state.Treatments.treatments,
    services: [...state.FormOptions.options.treatment],
    username: state?.User?.data?.username,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadUserProfileInfo: loadUserProfileInfo(dispatch),
    addNewTreatment: addNewTreatment(dispatch),
    loadAllTreatments: loadAllTreatments(dispatch),
    addNewForm: addNewForm(dispatch),
    addNewImage: addNewImage(dispatch),
    deleteImage: deleteImage(dispatch),
    deleteForm: deleteForm(dispatch),
    deleteTreatment: deleteTreatment(dispatch),
    dispatch: dispatch
  };
};

const Profile = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class extends Component {
    constructor(props) {
      super(props);
      this.overlayRef = React.createRef();
      this.state = {
        addTreatmentLoading: false,
        modalShow: false,
        sortParams: { sortBy: "number", order: "ascending" },
        initialTreatmentState: {
          treatmentType: "",
          date: null,
          imageUrls: [],
          forms: []
        },
        initialValidInputState: {
          treatmentType: true,
          date: true
        },
        addTreatment: {
          treatmentType: "",
          date: null,
          imageUrls: [],
          forms: []
        },
        validInput: {
          treatmentType: true,
          date: true
        },
        lightbox: {
          treatmentIndex: 0,
          photoIndex: 0,
          isOpen: false
        },
        viewAllImages: false,
        viewAllPhotoIndex: 0
      };
    }

    sortingOptions = [
      { key: "number", value: "Nr" },
      { key: "name", value: "Treatment" },
      { key: "date", value: "Date" }
    ];

    fileInput = "";

    changeSortParams = option => {
      const { sortParams } = this.state;
      if (sortParams.sortBy === option && sortParams.order === "ascending") {
        this.setState({ sortParams: { sortBy: option, order: "descending" } });
        return;
      }
      this.setState({ sortParams: { sortBy: option, order: "ascending" } });
    };

    changeViewAllImages = val =>
      this.setState({ viewAllImages: val, viewAllPhotoIndex: 0 });

    openLightbox = (treatmentIndex, photoIndex) =>
      this.setState({
        lightbox: {
          treatmentIndex: treatmentIndex,
          photoIndex: photoIndex,
          isOpen: true
        }
      });

    renderTableHeadings = () => {
      const { sortParams } = this.state;
      return this.sortingOptions.map((option, index) => {
        return (
          <th key={index}>
            <span className="d-flex align-items-center">
              {option.value}
              <i
                className={
                  option.key === sortParams.sortBy &&
                  sortParams.order === "descending"
                    ? "icon-caret-up"
                    : "icon-caret-down"
                }
                onClick={() => this.changeSortParams(option.key)}
              ></i>
            </span>
          </th>
        );
      });
    };

    // actionTypes = ["action_1", "action_2", "action_3"]; // *****Will be updated based on actual inputs*****

    componentDidMount() {
      this.props.loadUserProfileInfo(this.props.match.params.customerId);
      loadFormOptions(this.props.dispatch);
      this.props.loadAllTreatments(
        this.props.match.params.customerId,
        this.state.sortParams
      );
      // this.props.loadUserTreatments ***** Will be added once backend is setup *****
    }

    componentDidUpdate(prevProps, prevState) {
      const { sortParams, search } = this.state;
      if (
        sortParams.sortBy !== prevState.sortParams.sortBy ||
        sortParams.order !== prevState.sortParams.order
      ) {
        this.props.loadAllTreatments(
          this.props.match.params.customerId,
          this.state.sortParams
        );
      }
    }

    componentWillUnmount() {
      clearUserProfileInfo(this.props.dispatch);
    }

    setModalShow = val => this.setState({ modalShow: val });

    handleClose = () => {
      this.resetAddTreatment();
      this.setModalShow(false);
    };

    invokeClose = () => {
      const { addTreatment, initialTreatmentState } = this.state;
      if (!isEqual(addTreatment, initialTreatmentState)) {
        const response = window.confirm(
          "You have unsaved changes that will be lost."
        );
        if (response) {
          this.handleClose();
        }
      } else {
        this.handleClose();
      }
    };

    resetAddTreatment = () => {
      this.setState(prevState => ({
        addTreatment: prevState.initialTreatmentState,
        validInput: prevState.initialValidInputState
      }));
    };

    changeTreatmentType = val =>
      this.setState({
        addTreatment: { ...this.state.addTreatment, treatmentType: val },
        validInput: { ...this.state.validInput, treatmentType: true }
      });
    changeTreatmentDate = val =>
      this.setState({
        addTreatment: { ...this.state.addTreatment, date: val },
        validInput: { ...this.state.validInput, date: true }
      });
    addTreatmentForm = val =>
      this.setState({
        addTreatment: {
          ...this.state.addTreatment,
          forms: [...this.state.addTreatment.forms, val]
        }
      });

    checkValidInput = key => {
      const { validInput, addTreatment } = this.state;
      if (!addTreatment[key]) {
        this.setState({ validInput: { ...validInput, [key]: false } });
        return;
      }
    };

    checkValidFormInputs = () => {
      const { validInput, addTreatment } = this.state;
      const modify = {};
      let invalidInput = false;
      Object.keys(validInput).map(key => {
        if (!validInput[key] || !addTreatment[key]) {
          modify[key] = false;
          invalidInput = true;
        }
      });
      this.setState({ validInput: { ...validInput, ...modify } });
      return invalidInput;
    };

    canSaveNewTreatment = () => {
      const { validInput, addTreatment } = this.state;
      let disabled = false;
      Object.keys(validInput).forEach(key => {
        if (!validInput[key] || !addTreatment[key]) {
          disabled = true;
        }
      });
      return disabled;
    };

    setCustomerImage = async e => {
      const { files } = e.target;
      const { imageUrls } = this.state.addTreatment;

      if (files && files.length) {
        if (imageUrls.length + files.length > 15) {
          // showNotification(
          //   "warn",
          //   "Limit Reached",
          //   "Cannot add more than 5 images"
          // );
          alert("Limit Reached: Cannot add more than 15 images");
          return;
        }
        const filteredFiles = Object.keys(files).filter(
          index =>
            files[index].size < IMAGE_SIZE_LIMIT &&
            ALLOWED_IMAGE_TYPES.includes(files[index].type)
        );
        const urls = filteredFiles.map(index => {
          return URL.createObjectURL(files[index]);
        });
        if (urls.length) {
          this.setState({
            addTreatment: {
              ...this.state.addTreatment,
              imageUrls: [...imageUrls, ...urls]
            }
          });
        }
        if (filteredFiles.length < files.length) {
          alert("Files That Exceeded File Size Limit(10MB) Were Skipped");
          alert("Files other than png, jpg and svg Were Skipped");
        }
        if (this.fileInput) {
          this.fileInput.value = "";
        }
      }
    };

    // addFormToExistingTreatment = (index, val) => {}; // ***** Will be implemented once the backend is setup *****
    saveNewTreatment = async () => {
      const { treatments } = this.props;
      const { addTreatment } = this.state;
      const { dispatch } = this.props;
      if (this.checkValidFormInputs()) {
        return;
      }
      if (this.state.addTreatmentLoading) {
        showNotification(
          "info",
          "Add Treatment In Progress",
          "Please wait until the call is finished"
        );
        return;
      }
      this.setState({ addTreatmentLoading: true });
      const err = await this.props.addNewTreatment(
        addTreatment,
        this.props.match.params.customerId,
        treatments?.length > 0
          ? Math.max(
              ...treatments.map(treatment =>
                treatment?.num ? treatment.num : 1
              )
            ) + 1
          : 1
      );
      this.setState({ addTreatmentLoading: false });
      if (err) {
        showNotification("error", "Add Treatment Error", err);
      } else {
        showNotification(
          "success",
          "Add Treatment Success",
          "Added a new treatment successfully"
        );
        this.resetAddTreatment();
        this.handleClose();
      }
    }; // ***** Will be implemented once the backend is setup *****

    renderModal = () => {
      const {
        modalShow,
        addTreatment,
        validInput,
        addTreatmentLoading
      } = this.state;
      const { customer, treatments } = this.props;
      return (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="app-modal profile-modal profile-modal-set"
          scrollable
          show={modalShow}
          onHide={this.invokeClose}
          backdrop="static"
        >
          <Modal.Body>
            <Form className="w-100">
              <div className="customer-profile profileModal-main">
                <div className="modal-canvas">
                  <div className="cp-inner mb-0">
                    <div className="user-profile user-profile-lg d-flex align-items-center">
                      <div className="user-img-canvas">
                        <div className="user-img">
                          <img
                            src={
                              customer.imageUrl
                                ? customer.imageUrl
                                : DEFAULT_IMAGE
                            }
                            alt="User Img"
                          />
                        </div>
                      </div>
                      <div className="user-detail">
                        {this.props.username != "shannonboddie" ? (
                          <h2 className="app-heading">{<div>{customer.name ? customer.name.slice(0, 3).padEnd(10, '*') : ""}</div>}</h2>
                        ) : (
                          <h2 className="app-heading">{<div>{customer.name ? customer.name : ""}</div>}</h2>
                        )}
                        {customer.allergy && (
                          <div className="d-flex align-items-center">
                            <div className="exclamation-mark bg-primary">
                              <i className="icon-warning"></i>
                            </div>
                            <p className="app-paragraph mb-0">
                              {customer.allergy}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="profile-modal-row">
                      <label className="mb-0">Treatment Number:</label>
                      <span>
                        {treatments?.length > 0
                          ? Math.max(
                              ...treatments.map(treatment =>
                                treatment?.num ? treatment.num : 1
                              )
                            ) + 1
                          : 1}
                      </span>
                    </div>
                    <Dropdown className="treatment-dropdown">
                      <Form.Group className="modal-group modalGroup-name mb-0">
                        <Form.Control
                          type="text"
                          value={addTreatment.treatmentType}
                          placeholder="Treatment Type"
                          isInvalid={!validInput.treatmentType}
                          onChange={e =>
                            this.changeTreatmentType(e.target.value)
                          }
                          onBlur={() => this.checkValidInput("treatmentType")}
                        />
                      </Form.Group>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="digit-box bg-primary"
                      >
                        <i className="icon-arrow-down"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="scrollbar-set">
                        {this.props.services.map((service, index) => {
                          return (
                            <Dropdown.Item
                              onClick={e =>
                                this.changeTreatmentType(e.target.text)
                              }
                              key={index}
                            >
                              {service.label}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="profile-modal-row profileRow-date">
                      <label className="mb-0">Treatment Date:</label>
                      <DateSelector
                        value={addTreatment.date}
                        handleChange={date => this.changeTreatmentDate(date)}
                        onBlur={this.checkValidInput}
                        field="date"
                      />
                    </div>
                    <div className="profile-modal-row profileRow-opt">
                      <div className="attachment-block photoAttach-block flex-wrap">
                        <div className="attachment-inner flex-wrap">
                          <label className="mb-0">Images:</label>
                          {addTreatment.imageUrls.map((url, index) => (
                            <div className="attachment-box" key={index}>
                              <img src={url} alt="User Img" />
                            </div>
                          ))}
                          <label className="custom-file-upload">
                            <i className="icon-plus"></i>
                            <input
                              type="file"
                              onChange={this.setCustomerImage}
                              accept="image/*"
                              multiple
                              ref={ref => (this.fileInput = ref)}
                            />
                            {/* *****  file upload to be added here once db implemented***** */}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="profile-modal-row profileRow-opt">
                      <label className="mb-0">Forms:</label>
                      <div className="attachment-block formAdd-block flex-wrap">
                        <div className="attachment-inner flex-wrap">
                          {addTreatment.forms.map((form, index) => (
                            <div
                              className="attachment-box bg-primary text-white"
                              key={index}
                            >
                              <i
                                className={
                                  form === "MSR" ? "icon-cream" : "icon-camera"
                                }
                              ></i>
                            </div>
                          ))}
                        </div>
                        <Dropdown className="app-dropdown">
                          <Dropdown.Toggle id="dropdown-basic">
                            <i className="icon-plus"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => this.addTreatmentForm("PDA")}
                              // href="#/action-1"
                            >
                              <i className="icon-camera"></i>
                              PDA
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => this.addTreatmentForm("MSR")}
                              // href="#/action-2"
                            >
                              <i className="icon-cream"></i>
                              MSR
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={this.saveNewTreatment}
                    disabled={this.canSaveNewTreatment() || addTreatmentLoading}
                    className="btn addCustomer-btn addCustomer-btn-complete"
                  >
                    {addTreatmentLoading ? "Adding" : "Add Treatment"}
                    {addTreatmentLoading && <SmallLoader />}
                  </Button>
                </div>
                <Button
                  onClick={this.invokeClose}
                  className="btn close-btn close-circle"
                >
                  <i className="icon-cross"></i>
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      );
    };

    renderTreatments = () => {
      return this.props.treatments.map((treatment, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="digit-box bg-primary">
                {treatment.num ? treatment.num : index + 1}
              </div>
            </td>
            <td>
              <span className="font-weight-bold">
                {treatment.treatmentType}
              </span>
            </td>
            <td>
              <span className="font-weight-bold">
                {getHumanizeTime(treatment.date)}
              </span>
            </td>
            <td>
              <div className="attachment-block formAdd-block">
                <div className="attachment-inner">
                  {treatment.forms.map((form, formIndex) => (
                    <div className="attachment-off" key={formIndex}>
                      <div
                        onClick={e => {
                          if (form.type === "MSR") {
                            this.props.history.push(
                              `/treatmentmsr/${this.props.match.params.customerId}/${form._id}`
                            );
                          } else {
                            this.props.history.push(
                              `/treatmentpda/${this.props.match.params.customerId}/${form._id}`
                            );
                          }
                          // ***** Will be modified based on ID and form *****
                        }}
                        className="attachment-box text-primary"
                      >
                        <i
                          className={
                            form.type === "MSR" ? "icon-cream" : "icon-camera"
                          }
                        ></i>
                      </div>
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        rootCloseEvent="click"
                        rootClose
                        overlay={
                          <Popover className="popover-custom">
                            <Popover.Title>
                              <h4>
                                Are you sure you want to delete this form?
                              </h4>
                            </Popover.Title>
                            <Popover.Content>
                              <Button
                                onClick={() => {
                                  this.props.deleteForm(
                                    treatment.id,
                                    form._id,
                                    index,
                                    formIndex
                                  );
                                }}
                                disabled={this.props.username != "shannonboddie"}
                              >
                                Delete
                              </Button>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <div className="attachment-cross">
                          <i className="icon-cross"></i>
                        </div>
                      </OverlayTrigger>
                    </div>
                  ))}
                </div>
                <Dropdown className="app-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    <i className="icon-plus"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={e => {
                        this.props.addNewForm(treatment.id, "PDA");
                      }}
                      // href="#/action-1"
                    >
                      <i className="icon-camera"></i>
                      PDA
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={e => {
                        this.props.addNewForm(treatment.id, "MSR");
                      }}
                      // href="#/action-2"
                    >
                      <i className="icon-cream"></i>
                      MSR
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </td>
            <td>
              <div className="attachment-block photoAttach-block">
                <div className="attachment-inner">
                  {treatment.imageUrls.map((url, photoIndex) => (
                    <div className="attachment-off" key={photoIndex}>
                      <div className="attachment-box">
                        {this.props.username != "shannonboddie" ? (
                          <i className="icon-camera"></i>
                        ) : (
                          <img
                            src={url}
                            alt="User Img"
                            onClick={e => this.openLightbox(index, photoIndex)}
                          />
                        )}
                      </div>
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        rootCloseEvent="click"
                        rootClose
                        overlay={
                          <Popover className="popover-custom">
                            <Popover.Title>
                              <h4>
                                Are you sure you want to delete this image?
                              </h4>
                            </Popover.Title>
                            <Popover.Content>
                              <Button
                                onClick={() => {
                                  this.props.deleteImage(
                                    treatment.id,
                                    treatment?.imageObjs[photoIndex]?._id,
                                    index,
                                    photoIndex
                                  );
                                }}
                                disabled={this.props.username != "shannonboddie"}
                              >
                                Delete
                              </Button>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <div className="attachment-cross">
                          <i className="icon-cross"></i>
                        </div>
                      </OverlayTrigger>
                    </div>
                  ))}
                </div>
                <label className="custom-file-upload">
                  <i className="icon-plus"></i>
                  <input
                    type="file"
                    onChange={e => {
                      this.props.addNewImage(treatment.id, e);
                    }}
                    accept="image/*"
                  />
                  {/* *****  file upload to be added here once db implemented***** */}
                </label>
              </div>
            </td>
            <td>
              <OverlayTrigger
                trigger="click"
                placement="left"
                rootClose
                rootCloseEvent="click"
                overlay={
                  <Popover className="popover-custom">
                    <Popover.Title>
                      <h4>Are you sure you want to delete this treatment?</h4>
                    </Popover.Title>
                    <Popover.Content>
                      <Button
                        onClick={() => this.props.deleteTreatment(treatment.id)}
                        disabled={this.props.username != "shannonboddie"}
                      >
                        Delete
                      </Button>
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="remove-treatment">
                  <i className="icon-plus"></i>
                </div>
              </OverlayTrigger>
            </td>
          </tr>
        );
      });
    };

    render() {
      const { customer, treatments } = this.props;
      const { viewAllImages, viewAllPhotoIndex } = this.state;
      const { isOpen, treatmentIndex, photoIndex } = this.state.lightbox;

      let allImageLinks = [];
      if (viewAllImages) {
        treatments.map(treatment => {
          if (treatment.imageUrls.length) {
            allImageLinks = [...allImageLinks, ...treatment.imageUrls];
          }
        });
        if (allImageLinks.length === 0) {
          this.changeViewAllImages(false);
        }
      }

      return (
        <ReusableComponents.OffCanvasContainer>
          <Sidebar />
          <div className="main-content profile-content">
            <NavigationButtons />
            <div className="row">
              <div className="col-12 col-xl-11">
                <ProfileInfo
                  customerName={customer.name}
                  customerDOB={customer.dob}
                  customerEmail={customer.email}
                  customerPhone={customer.phone}
                  customerAddress={customer.address}
                  customerImageUrl={customer.imageUrl}
                  customerAllergy={customer.allergy}
                  customerId={customer.id}
                  dispatch={this.props.dispatch}
                  canEdit
                />
                <div className="table-canvas">
                  <Table responsive="xl" className="app-table profile-table">
                    <thead>
                      <tr>
                        {this.renderTableHeadings()}
                        <th>
                          <span className="d-flex align-items-center">
                            Forms added
                          </span>
                        </th>
                        <th>
                          <span className="d-flex align-items-center">
                            Photos attached
                            <ul className="view-list">
                              <li>
                                <a
                                  onClick={() => this.changeViewAllImages(true)}
                                >
                                  View All
                                </a>
                              </li>
                            </ul>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderTreatments()}</tbody>
                  </Table>
                </div>
              </div>
            </div>
            <button
              onClick={() => this.setModalShow(true)}
              className="plus-circle plus-fixed bg-primary"
            >
              <i className="icon-plus"></i>
            </button>
            {this.renderModal()}
          </div>
          {isOpen && treatments[treatmentIndex].imageUrls.length && (
            <Lightbox
              mainSrc={treatments[treatmentIndex].imageUrls[photoIndex]}
              onCloseRequest={() => {
                this.setState({
                  lightbox: {
                    treatmentIndex: treatmentIndex,
                    photoIndex: photoIndex,
                    isOpen: false
                  }
                });
              }}
            />
          )}
          {viewAllImages && allImageLinks.length && (
            <Lightbox
              mainSrc={allImageLinks[viewAllPhotoIndex]}
              nextSrc={
                allImageLinks[(viewAllPhotoIndex + 1) % allImageLinks.length]
              }
              prevSrc={
                allImageLinks[
                  (viewAllPhotoIndex + allImageLinks.length - 1) %
                    allImageLinks.length
                ]
              }
              onCloseRequest={() => this.changeViewAllImages(false)}
              onMovePrevRequest={() =>
                this.setState({
                  viewAllPhotoIndex:
                    (viewAllPhotoIndex + allImageLinks.length - 1) %
                    allImageLinks.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  viewAllPhotoIndex:
                    (viewAllPhotoIndex + 1) % allImageLinks.length
                })
              }
            />
          )}
        </ReusableComponents.OffCanvasContainer>
      );
    }
  }
);

// ***** All of the customerProps will be combined into one once the db is setup *****

Profile.propTypes = {
  customer: PropTypes.object,
  treatments: PropTypes.arrayOf(PropTypes.object),
  services: PropTypes.arrayOf(PropTypes.object)
};

Profile.defaultProps = {
  customer: {
    name: "Not Random Name",
    dob: "Random DOB",
    email: "Random Email",
    phone: "Random Phone",
    address: "Random Address",
    imageUrl: "Random URL",
    allergy: "Random Allergy",
    id: "Random id"
  },
  treatments: [
    {
      id: "69696969",
      treatmentType: "Oxygen Facial",
      date: "05/01/2020",
      imageUrls: ["/images/customer-10.png"],
      forms: []
    },
    {
      id: "696969",
      treatmentType: "Chemical Peel",
      date: "08/01/2020",
      imageUrls: ["/images/customer-5.png", "/images/customer-8.png"],
      forms: []
    }
  ],
  services: []
};

export default withRouter(Profile);
