import { cloneDeep } from "lodash";

const initialState = {
  loading: false,
  error: null,
  customers: []
};

const Customers = (state = initialState, action) => {
  if (action.type === "ADD_NEW_CUSTOMER") {
    const stateCopy = cloneDeep(state.customers);
    stateCopy.push(action.payload);
    return { customers: stateCopy };
  }
  if (action.type === "LOADING_CUSTOMERS") {
    return { loading: true, err: null, ...state };
  }
  if (action.type === "LOADING_CUSTOMERS_SUCCESSFUL") {
    const { payload } = action;
    return {
      loading: false,
      err: null,
      customers: [...payload.customers]
    };
  }
  if (action.type === "LOADING_CUSTOMERS_UNSUCCESSFUL") {
    const { payload } = action;
    return { loading: false, err: payload.err, customers: [] };
  }
  if (action.type === "DELETE_CUSTOMER") {
    const { customerId } = action.payload;
    return {
      ...state,
      customers: state.customers.filter(customer => customer.id !== customerId)
    };
  }
  return state;
};

export default Customers;
