import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { loadUserInfo } from "../../Redux/actions/user";
import Customer from "../Customer";
import Profile from "../Profile";
import { connect } from "react-redux";
import MSRTreatmentForm from "../TreatmentForms/MSRTreatmentForm";
import PDATreatmentForm from "../TreatmentForms/PDATreatmentForm";
import SettingsForm from "../SettingsForm";
import Loader from "../../Components/Common/loader";

const mapStateToProps = state => {
  return {
    me: state.User.data,
    meLoading: state.User.loading,
    meError: state.User.err,
    meHasData: state.User.hasData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadMe: loadUserInfo(dispatch),
    dispatch: dispatch
  };
};

const Layout = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class extends Component {
    componentDidMount() {
      this.props.loadMe();
    }

    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        if (!this.props.meLoading && this.props.meError) {
          this.props.history.push(
            `/login?return_url=${encodeURIComponent(window.location.href)}`
          );
        }
      }
    }

    render() {
      if (this.props.meLoading) {
        return <Loader />;
      } else if (this.props.me) {
        return (
          <Switch>
            <Route exact path="/customer" component={Customer} />
            <Route exact path="/profile/:customerId" component={Profile} />
            <Route
              exact
              path="/treatmentmsr/:customerId/:formId"
              component={MSRTreatmentForm}
            />
            <Route
              exact
              path="/treatmentpda/:customerId/:formId"
              component={PDATreatmentForm}
            />
            <Route exact path="/settings" component={SettingsForm} />
            <Route render={() => <h1>Page Not Found</h1>} />
          </Switch>
        );
      } else {
        return <></>;
      }
    }
  }
);

export default withRouter(Layout);
