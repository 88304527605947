import * as yup from 'yup';
import { EMAIL_REGEX } from '../../constants';
const INVALID_EMAIL_ERROR = "please enter a valid email"

export const EMAIL_FORM  = {
    EMAIL : 'email'
}
export const EmailSchema = yup.object().shape({
    [EMAIL_FORM.EMAIL]: yup
      .string()
      .trim()
      .matches(EMAIL_REGEX, INVALID_EMAIL_ERROR)
      .required('Email is required'),
  });