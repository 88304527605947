import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useLogin } from "../../Redux/actions/login";
import { Form, Button } from "react-bootstrap";
import SmallLoader from "../../Components/Common/small.loader";
import { errorExtractor, showNotification } from "../../Utils/misc.utils";
import qs from "query-string";

const Login = () => {
  const history = useHistory();
  const queryParams = qs.parse(history.location.search);
  const { res: loginResponse, login } = useLogin();
  const { errors, handleSubmit, register } = useForm({
    validationSchema: yup.object().shape({
      username: yup
        .string()
        .matches(
          new RegExp("^[A-Z0-9]*$", "gi"),
          "Only alphanumerics are allowed"
        )
        .required("This field is required"),
      password: yup
        .string()
        .trim()
        .required("This field is required")
    })
  });

  useEffect(() => {
    if (!loginResponse.loading && loginResponse.data) {
      if (queryParams && queryParams.return_url) {
        try {
          const returnUrl = new URL(queryParams.return_url);
          window.location.href = returnUrl.href;
        } catch (e) {
          console.error(e);
          history.push("/customer");
        }
      } else {
        history.push("/customer");
      }
    }
    if (!loginResponse.loading && loginResponse.error) {
      showNotification("error", "Error", errorExtractor(loginResponse.error));
    }
  }, [loginResponse]);

  const logMeIn = (data, e) => {
    login({
      username: data.username,
      password: data.password
    });
  };

  return (
    <div className="form--page login--page">
      <div className="container container--app">
        <div className="text-center">
          <a href="" className="app__logo">
            <img src="/images/logo.png" />
          </a>
        </div>
        <div className="app--canvas login--canvas">
          <div className="form--content">
            <h2 className="app__heading text-sm-center">Login</h2>
            <Form className="form--main" onSubmit={handleSubmit(logMeIn)}>
              <div className="row row--space--11">
                <div className="col-12 col-md-12">
                  <Form.Group className="fields--group">
                    <i className="icon-mail"></i>
                    <Form.Control
                      ref={register}
                      name="username"
                      type="text"
                      placeholder="Username"
                      className={errors?.username?.message ? "error is-invalid" : ""}
                    />
                    {errors?.username?.message && (
                      <p className="error__msg">{errors?.username?.message}</p>
                    )}
                  </Form.Group>
                </div>
                <div className="col-12 col-md-12">
                  <Form.Group className="fields--group mb-3">
                    <i className="icon-lock"></i>
                    <Form.Control
                      ref={register}
                      name="password"
                      type="password"
                      placeholder="Password"
                      className={errors?.password?.message ? "error is-invalid" : ""}
                    />
                    {errors?.password?.message && (
                      <p className="error__msg">{errors?.password?.message}</p>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex justify-content-end forgot--pass">
                <a href="#" className="forgotPass__link">
                  Forgot Password?
                </a>
              </div>
              <div className="form--btn--canvas login--btn--canvas">
                <Button
                  type="submit"
                  className="btn__form"
                  disabled={loginResponse.loading}
                >
                  {loginResponse.loading ? "Signing In" : "Sign In"}
                  {loginResponse.loading && <SmallLoader />}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
