const initialState = {
  showSidebar: false
};

const Sidebar = (state = initialState, action) => {
  if (action.type === "CHANGE_SIDEBAR_VISIBILITY") {
    return { showSidebar: !state.showSidebar };
  }
  return state;
};
export default Sidebar;
