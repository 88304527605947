import { cloneDeep } from "lodash";

const initialState = {
  loading: false,
  err: null,
  treatments: [],
};

const Treatments = (state = initialState, action) => {
  if (action.type === "ADD_NEW_TREATMENT") {
    const stateCopy = cloneDeep(state.treatments);
    stateCopy.push(action.payload);
    return { treatments: stateCopy };
  }
  if (action.type === "LOADING_TREATMENTS") {
    return { loading: true, err: null, treatments: [] };
  }
  if (action.type === "LOADING_TREATMENTS_SUCCESSFUL") {
    const { payload } = action;
    return { loading: false, err: null, treatments: [...payload.treatments] };
  }
  if (action.type === "LOADING_TREATMENTS_UNSUCCESSFUL") {
    const { payload } = action;
    return { loading: false, err: payload.err, treatments: [] };
  }
  if (action.type === "ADD_NEW_TREATMENT_FORM_OR_IMAGE") {
    const { payload } = action;
    const stateCopy = cloneDeep(state);

    stateCopy.treatments.forEach((treatment, index) => {
      if (treatment.id === payload.id) {
        stateCopy.treatments[index][payload.type] = payload.data;
        if (payload.type === "imageUrls") {
          stateCopy.treatments[index]["imageObjs"] = payload.imageObjs;
        }
      }
    });
    return stateCopy;
  }
  if (action.type === "DELETE_TREATMENT_IMAGE") {
    const { payload } = action;
    const stateCopy = cloneDeep(state);
    stateCopy.treatments[payload.index]["imageUrls"].splice(
      payload.photoIndex,
      1
    );
    stateCopy.treatments[payload.index]["imageObjs"].splice(
      payload.photoIndex,
      1
    );
    return stateCopy;
  }
  if (action.type === "DELETE_TREATMENT_FORM") {
    const { payload } = action;
    const stateCopy = cloneDeep(state);
    stateCopy.treatments[payload.index]["forms"].splice(payload.formIndex, 1);
    return stateCopy;
  }
  if (action.type === "DELETE_TREATMENT") {
    const stateCopy = cloneDeep(state);
    stateCopy.treatments = stateCopy.treatments.filter(treatment => treatment.id !== action.payload.treatmentId);
    return stateCopy;
  }

  return state;
};

export default Treatments;
