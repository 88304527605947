import React, { Component } from "react";

import Select from "react-select";

import PropTypes from "prop-types";

export default class CreatableMulti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFields: this.props.selected,
    };
  }

  updateSelectedFields = (val) => {
    this.setState({ selectedFields: val }, this.props.updateParentState(val));
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        selectedFields: JSON.parse(JSON.stringify(this.props.selected)),
      });
    }
  }

  render() {
    return (
      <div style={{ width: "auto" }} className="Creatable-Select-Multi">
        <Select
          placeholder={this.props.placeholder}
          value={this.state.selectedFields}
          isMulti={this.props.isMulti}
          onChange={this.updateSelectedFields}
          options={this.props.options}
          styles={{
            option: (styles, { data }) => {
              return {
                ...styles,
                color: data?.color ? data?.color : "#555555",
              };
            },
            multiValue: (styles, { data }) => {
              return {
                ...styles,
                backgroundColor: data?.color ? data?.color : "#f2594f",
              };
            },
            multiValueRemove: (styles, { data }) => {
              return {
                ...styles,
                color: "#FFFFFF",
              };
            },
          }}
        />
      </div>
    );
  }
}

CreatableMulti.propTypes = {
  selected: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  updateParentState: PropTypes.func,
  isMulti: PropTypes.bool,
};

CreatableMulti.defaultProps = {
  selected: [],
  options: [
    { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
    { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630", isFixed: true },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
  ],
  isMulti: true,
  placeholder: "Select...",
  updateParentState: (val) => {},
};
