import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createAction } from "redux-actions";

const mapStateToProps = state => {
  return { stackLength: state.RouteStack.visited.length };
};

const NavigationButtons = connect(mapStateToProps)(
  class extends Component {
    constructor(props) {
      super(props);
    }
    render() {
      const { history, dispatch, stackLength } = this.props;

      return (
        <div className="d-flex justify-contnent-between align-items-center mb-4">
          {stackLength ? (
            <a onClick={history.goBack} className="white-btn-rounded back-btn">
              <i className="icon-arrow-left"></i>
            </a>
          ) : null}

          <button
            onClick={() => {
              dispatch(createAction("CHANGE_SIDEBAR_VISIBILITY")({}));
            }}
            className="navbar-toggler"
          >
            <span className="bg-primary"></span>
            <span className="bg-primary"></span>
            <span className="bg-primary"></span>
          </button>
        </div>
      );
    }
  }
);

NavigationButtons.propTypes = {
  dispatch: PropTypes.func
};

NavigationButtons.defaultProps = {
  dispatch: () => {}
};

export default withRouter(NavigationButtons);
