const initialState = {
  loading: false,
  data: null,
  err: null,
  hasData: false
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case "ME_LOADING":
      return {
        ...state,
        loading: true
      };

    case "ME_SUCCESSFUL":
      return {
        ...state,
        loading: false,
        data: action.payload,
        err: null,
        hasData: true
      };

    case "ME_FAILURE":
      return {
        ...state,
        loading: false,
        data: null,
        err: action.payload,
        hasData: true
      };
    default:
      return state;
  }
};
export default User;
