import * as moment from "moment";
import { cloneDeep } from "lodash";

const initialState = {
  loading: false,
  err: null,
  formDetails: {
    date: null,
    skinCareSpecialist: undefined,
    concern: "",
    skinType: undefined,
    conditions: [],
    fitzpatrick: undefined,
    glogauClassification: undefined,
    homeCareProg: {
      product: [
        "Cleanser",
        "AM Moisturizer",
        "AM UV Protection",
        "Pigment Treatment",
        "Topical Acne Medication",
        "Internal Acne Medication",
        "AHA Product",
        "Retin-A/Topical Presctiption",
        "Exfoliator",
        "PM Hydrating/TX Creme"
      ],
      drugStore: {
        "0": "",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": ""
      },
      deptStore: {
        "0": "",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": ""
      },
      professional: {
        "0": "",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": ""
      },
      rx: {
        "0": "",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": ""
      }
    },
    homeCareRecommendation: {
      am: ["Step 1", "Step 2", "Step 3", "Step 4"],
      productOne: {
        "0": [],
        "1": [],
        "2": [],
        "3": []
      },
      pm: {
        "0": "",
        "1": "",
        "2": "",
        "3": ""
      },
      productTwo: {
        "0": [],
        "1": [],
        "2": [],
        "3": []
      }
    },
    profTreatPlan: {
      treatment: ["1", "2", "3", "4"],
      recommendedTreatment: {
        "0": "",
        "1": "",
        "2": "",
        "3": ""
      },
      scheduleDate: {
        "0": null,
        "1": null,
        "2": null,
        "3": null
      }
    },
    notes: ""
  }
};

const MSRFormData = (state = initialState, action) => {
  if (action.type === "LOADING_MSR_FORM_DATA") {
    return {
      ...state,
      loading: true,
      err: null
    };
  }
  if (action.type === "LOADING_MSR_FORM_DATA_SUCCESSFUL") {
    // console.log(moment().toDate(), action.payload);
    // debugger;
    return action.payload
      ? {
          ...state,
          formDetails: action.payload,
          loading: false,
          err: null
        }
      : {
          loading: false,
          err: null,
          formDetails: {
            ...cloneDeep(initialState.formDetails),
            date: moment().toDate()
          }
        };
  }
  if (action.type === "LOADING_MSR_FORM_DATA_UNSUCCESSFUL") {
    return {
      ...state,
      err: action.payload
    };
  }
  if (action.type === "UPDATE_MSR_FORM_DATA") {
    return action.payload
      ? {
          ...state,
          formDetails: action.payload,
          loading: false,
          err: null
        }
      : initialState;
  }
  if (action.type === "CLEAR_MSR_FORM_DATA") {
    return cloneDeep(initialState);
  }
  return state;
};

export default MSRFormData;
