import axios from "axios";
import env from "./env.utils";

const api = axios.create({
  baseURL: env.apiUrl,
  withCredentials: true
});

api.interceptors.response.use(
  res => {
    return res;
  },
  e => {
    if (
      e &&
      e.response &&
      e?.response?.status === 401 &&
      !window.location.pathname.startsWith("/login")
    ) {
      window.location.href = `${window.location.origin}/login?return_url=${encodeURIComponent(window.location.href)}`;
    }
    throw e;
  }
);

export default api;
