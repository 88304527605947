import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Layout from "./Containers/Layout";
import { connect } from "react-redux";
import { createAction } from "redux-actions";
import Login from "./Containers/Login";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authenticated = true;
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const AppRoutes = connect()(
  class Routes extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      const { history } = this.props;
      history.listen((routeObject, type) => {
        if (type === "PUSH") {
          this.props.dispatch(createAction("PUSH_ROUTE")(routeObject));
        }
        if (type === "POP") {
          this.props.dispatch(createAction("POP_ROUTE")(routeObject));
        }
      });
    }

    render() {
      return (
        <Switch>
          <Route exact path="/" render={() => <Redirect to={"/customer"} />} />
          <Route exact path="/login" component={Login} />
          <PrivateRoute component={Layout} />
          <Route render={() => <h1>Page Not Found</h1>} />
        </Switch>
      );
    }
  }
);

export default withRouter(AppRoutes);
