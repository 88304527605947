import { isString, pickBy, identity } from "lodash";
import moment from "moment";
import { notification } from "antd";
import "antd/lib/notification/style/index.css";
import env from "./env.utils";

export const errorExtractor = error => {
  if (!error) {
    return null;
  } else if (isString(error?.response?.data?.message)) {
    return error?.response?.data?.message;
  } else if (isString(error?.data?.message)) {
    return error?.data?.message;
  } else if (isString(error?.message)) {
    return error?.message;
  } else {
    return "network Error";
  }
};

export const formatDate = date => {
  console.log(date);
  if (date) {
    const splitedDate = date?.split("/");
    if (splitedDate.length === 3) {
      return splitedDate[2] + "-" + splitedDate[1] + "-" + splitedDate[0];
    } else {
      return "";
    }
  }
  return "";
};

export const getHumanizeTime = date => {
  if (date) {
    const momentDate = moment(date);
    if (momentDate.isValid()) {
      return momentDate.format("Do MMM YYYY");
    }
  }
  return "N/A";
};

export const showNotification = (type, message, description) => {
  const obj = { message, description };
  switch (type) {
    case "error":
      notification.error(obj);
      break;

    case "success":
      notification.success(obj);
      break;

    case "info":
      notification.info(obj);
      break;

    case "warn":
      notification.warn(obj);
      break;

    case "warning":
      notification.warning(obj);
      break;

    default:
      break;
  }
};

export const compileImageUrl = imageUrl => {
  return env.mediaBaseUrl + imageUrl;
};

export const checkDateFormat = date => {
  if (
    date &&
    date.length &&
    date.length === 10 &&
    date[2] === "/" &&
    date[5] === "/"
  ) {
    return false;
  }
  return true;
};

export function cleanObject(obj) {
  return pickBy(obj, identity);
}
