import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Sidebar from "../../Components/Sidebar";
import NavigationButtons from "../../Components/NavigationButtons";
import ProfileInfo from "../../Components/ProfileInfo";
import CreatableMulti from "../../Components/SelectComponents/Creatable";
import { withRouter } from "react-router-dom";
import {
  loadUserProfileInfo,
} from "../../Redux/actions/profileInfo";
import { connect } from "react-redux";
import {
  getMSRForm,
  putMSRForm
} from "../../Redux/actions/msrFormData";
import { loadFormOptions } from "../../Redux/actions/formOptions";
import { showNotification } from "../../Utils/misc.utils";
import DateSelector from "../../Components/ReusableComponents/Datepicker";
import { cloneDeep } from "lodash";
import { createAction } from "redux-actions";
import SmallLoader from "../../Components/Common/small.loader";
import EmailModal from "../../Components/EmailModal";

const mapStateToProps = state => {
  return {
    customer: { ...state.ProfileInfo.profileInfo },
    formDetails: state.MSRFormData.formDetails,
    formOptions: state.FormOptions.options
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadFormOptions: loadFormOptions(dispatch),
    loadUserProfileInfo: loadUserProfileInfo(dispatch),
    getMSRForm: getMSRForm(dispatch),
    putMSRForm: putMSRForm(dispatch),
    dispatch: dispatch
  };
};

const MSRTreatmentForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class MSRForm extends Component {
    constructor(props) {
      super(props);
      const { formDetails } = props;
      this.state = {
        formData: cloneDeep(formDetails),
        saveFormLoading: false,
        printLoading: false,
        dirty: false,
        validInput: {
          date: true,
          profTreatPlan: {
            recommendedTreatment: {
              "0": true,
              "1": true,
              "2": true,
              "3": true
            }
          }
        },
        showEmailModal: false
      };
    }

    homeCareProg = [
      { key: "product", value: "Product" },
      { key: "drugStore", value: "Drugstore" },
      { key: "deptStore", value: "Dept Store" },
      { key: "professional", value: "Professional" },
      { key: "rx", value: "RX" }
    ];

    homeCareRecommendation = [
      { key: "am", value: "AM" },
      { key: "productOne", value: "Product" },
      { key: "pm", value: "PM" },
      { key: "productTwo", value: "Product" }
    ];

    profTreatPlan = [
      { key: "treatment", value: "Treatment" },
      { key: "recommendedTreatment", value: "Recommended Treatment" },
      { key: "scheduleDate", value: "Scheduled Date" }
    ];

    refObj = {
      homeCareProg: React.createRef(),
      homeCareRecommendation: React.createRef(),
      profTreatPlan: React.createRef()
    };

    containerRef = React.createRef();

    printDocument = async (wholePage = false) => {
      const input1 = this.refObj["homeCareRecommendation"].current;
      const input2 = this.refObj["profTreatPlan"].current;
      const { dirty } = this.state;
      let isFormUpdated = true;
      if (dirty) {
        this.setState({ printLoading: true });
        isFormUpdated = await this.saveForm(false, false);
        this.setState({ printLoading: false });
      }
      if (input1 && input2 && isFormUpdated && !wholePage) {
        const oldPage = document.body.innerHTML;
        document.body.innerHTML = `<div>${input1.innerHTML}${input2.innerHTML}</div>`;
        window.print();
        document.body.innerHTML = oldPage;
        this.props.dispatch(createAction("POPULATE_SESSION_WITH_ROUTES")({}));
        window.location.reload(false);
      }
      if (isFormUpdated && wholePage) {
        const wholeDocument = this?.containerRef?.current;
        if (wholeDocument) {
          const oldPage = document.body.innerHTML;
          document.body.innerHTML = wholeDocument.innerHTML;
          window.print();
          document.body.innerHTML = oldPage;
          this.props.dispatch(createAction("POPULATE_SESSION_WITH_ROUTES")({}));
          window.location.reload(false);
        }
      }
    };

    componentDidUpdate(prevProps) {
      if (prevProps != this.props) {
        this.setState({
          formData: cloneDeep(this.props.formDetails)
        });
      }
    }

    componentWillUnmount() {
      this.props.dispatch(createAction("CLEAR_MSR_FORM_DATA")({}));
    }

    componentDidMount() {
      this.props.dispatch(
        createAction("RELOAD_ROUTES_FROM_SESSION_STORAGE")({})
      );
      this.props.loadFormOptions.then(() => {
        this.props
          .getMSRForm(
            this.props.match.params.formId,
            this.props.formOptions,
            this.props?.history?.push
          )
          .then(({ error }) => {
            if (error) {
              showNotification("error", "Fetch Form Error", error);
            }
          });
      });
      this.props.loadUserProfileInfo(this.props.match.params.customerId);
      // this.props.loadMSRFormData ***** Will be added once backend is setup *****
      // this.props.loadUserFormOptions ***** Will be added once backend is setup *****
    }

    updateFormData = arr => val => {
      let objectToModify;
      let property;
      let { formData } = this.state;
      let formDataCopy = cloneDeep(formData);
      arr.forEach((elem, index) => {
        if (objectToModify) {
          objectToModify = objectToModify[property];
          property = elem;
        } else {
          objectToModify = formDataCopy;
          property = elem;
        }
      });
      objectToModify[property] = val;
      if (
        ["scheduleDate", "recommendedTreatment"].some(field =>
          arr.includes(field)
        )
      ) {
        this.checkValidInput(
          formDataCopy,
          "profTreatPlan",
          arr[arr.length - 1]
        );
      }
      this.setState({
        formData: formDataCopy,
        dirty: true
      });
    };

    checkValidInput = (formData, property, index) => {
      let { validInput } = this.state;
      if (
        property === "date" &&
        (formData[property] === "" || formData[property] === null)
      ) {
        validInput[property] = false;
        this.setState({
          validInput: validInput
        });
        return;
      }
      if (property === "profTreatPlan") {
        validInput[property]["recommendedTreatment"][index] = !(
          (formData[property]["recommendedTreatment"][index] !== "" &&
            formData[property]["scheduleDate"][index] === "") ||
          (formData[property]["recommendedTreatment"][index] === "" &&
            formData[property]["scheduleDate"][index])
        );
        this.setState({
          validInput: validInput
        });
      }
    };

    checkValidFormInputs = validInputObject => {
      const keys = Object.keys(validInputObject);
      for (const key of keys) {
        if (typeof validInputObject[key] === "object") {
          return this.checkValidFormInputs(validInputObject[key]);
        } else if (!validInputObject[key]) {
          return true;
        }
      }
      return false;
    };

    saveForm = async (goToTreatments = true, enableLoading = true) => {
      const { validInput } = this.state;
      if (this.checkValidFormInputs(validInput)) {
        showNotification(
          "warn",
          "Input Error",
          "Invalid input in form field(s)"
        );
        return;
      }
      if (enableLoading) {
        this.setState({ saveFormLoading: true });
      }
      const { error } = await this.props.putMSRForm(
        this.props.match.params.formId,
        this.state.formData,
        this.props.formOptions
      );
      if (enableLoading) {
        this.setState({ saveFormLoading: false });
      }
      if (error) {
        showNotification("error", "Update Error", error);
      } else {
        showNotification(
          "success",
          "Update Success",
          "Form Updated Successfully"
        );
        if (goToTreatments) {
          this.props.history.push(
            "/profile/" + this.props.match.params.customerId
          );
        } else {
          this.setState({ dirty: false });
          return true;
        }
      }
    };

    renderTable = (arr, type) => {
      const { formData, validInput } = this.state;
      const { formOptions } = this.props;
      return (
        <div ref={this.refObj[type]} className="table-responsive-set">
          <Table className="treatment-table">
            <thead>
              <tr>
                {arr.map((obj, index) => {
                  return <th key={index}>{obj.value}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {formData[type][arr[0].key].map((val, index) => {
                return (
                  <tr key={index}>
                    <td>{val}</td>
                    {arr.slice(1).map((obj, childIndex) => {
                      return (
                        <td key={childIndex}>
                          <Form.Group className="form-group-table">
                            {obj.value === "Product" ? (
                              <CreatableMulti
                                options={formOptions.products}
                                selected={formData[type][obj.key][index]}
                                updateParentState={this.updateFormData([
                                  type,
                                  obj.key,
                                  index
                                ])}
                              />
                            ) : obj.key === "scheduleDate" ? (
                              <DateSelector
                                value={formData[type][obj.key][index]}
                                handleChange={date => {
                                  this.updateFormData([type, obj.key, index])(
                                    date
                                  );
                                }}
                              />
                            ) : (
                              <Form.Control
                                value={formData[type][obj.key][index]}
                                onChange={e => {
                                  this.updateFormData([type, obj.key, index])(
                                    e.target.value
                                  );
                                }}
                                type="text"
                                isInvalid={
                                  obj?.key === "recommendedTreatment"
                                    ? !validInput[type][obj.key][index]
                                    : false
                                }
                                placeholder={
                                  obj?.key === "scheduleDate"
                                    ? "dd/mm/yyyy"
                                    : ""
                                }
                                onBlur={() => {
                                  if (
                                    obj?.key === "scheduleDate" ||
                                    obj?.key === "recommendedTreatment"
                                  ) {
                                    this.checkValidInput(formData, type, index);
                                  }
                                }}
                              />
                            )}
                          </Form.Group>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      );
    };

    render() {
      const {
        formData,
        validInput,
        saveFormLoading,
        printLoading,
        showEmailModal
      } = this.state;
      const { customer, formOptions } = this.props;
      return (
        <div className="off-canvas-container">
          <Sidebar />
          <div className="main-content treatmentDetail-content">
            <NavigationButtons />
            <div className="pr-xl-5">
              <ProfileInfo
                customerName={customer.name}
                customerDOB={customer.dob}
                customerEmail={customer.email}
                customerPhone={customer.phone}
                customerAddress={customer.address}
                customerImageUrl={customer.imageUrl}
                customerAllergy={customer.allergy}
                customerId={customer.id}
              />
              <div
                className="treatmentDetail-main pt-3"
                ref={this.containerRef}
              >
                <h2 className="app-heading">Mapping Skin Rejuvenation</h2>
                <p className="app-paragraph">(In office use only)</p>
                <Form>
                  <div className="treatmentDetail-row">
                    <div className="row row-space-ten">
                      <div className="col-12 col-sm-4 col-lg-2">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            Treatment Date
                          </Form.Label>
                          <DateSelector
                            value={formData.date}
                            handleChange={date =>
                              this.updateFormData(["date"])(date)
                            }
                            onBlur={this.checkValidInput}
                            field="date"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12 col-sm-4 col-lg-2">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            <div className="addPadding4px">
                              Skin Care Specialist
                            </div>
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.skinCareSpecialists}
                            selected={formData.skinCareSpecialist}
                            updateParentState={this.updateFormData([
                              "skinCareSpecialist"
                            ])}
                            isMulti={false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            #1 Concern
                          </Form.Label>
                          <Form.Control
                            value={formData.concern}
                            onChange={e =>
                              this.updateFormData(["concern"])(e.target.value)
                            }
                            type="text"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row row-space-ten row-max-width">
                      <div className="col-12 col-sm-2 col-lg-2">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            <div className="addPadding4px">
                              Fitzpatrick Type
                            </div>
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.fitzpatrick}
                            selected={formData.fitzpatrick}
                            updateParentState={this.updateFormData([
                              "fitzpatrick"
                            ])}
                            isMulti={false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12 col-sm-3 col-lg-3">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            <div className="addPadding4px">
                              Glogau Classification
                            </div>
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.glogauClassification}
                            selected={formData.glogauClassification}
                            updateParentState={this.updateFormData([
                              "glogauClassification"
                            ])}
                            isMulti={false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12 col-sm-3 col-lg-3">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            <div className="addPadding4px">Skin Type</div>
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.skinType}
                            selected={formData.skinType}
                            updateParentState={this.updateFormData([
                              "skinType"
                            ])}
                            isMulti={false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12 col-sm-4 col-lg-4">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            <div className="addPadding4px">Conditions</div>
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.conditions}
                            selected={formData.conditions}
                            updateParentState={this.updateFormData([
                              "conditions"
                            ])}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="treatment-program treatmentPro-home">
                      <h2 className="app-heading">Current Home Care Program</h2>
                      {this.renderTable(this.homeCareProg, "homeCareProg")}
                    </div>
                    <div className="treatment-program treatmentPro-recommend">
                      <h2 className="app-heading">
                        Home Care Program Recommendations
                      </h2>
                      <div className="text-right">
                        <button
                          className="save-button print-button"
                          onClick={e => {
                            e.preventDefault();
                            this.printDocument();
                          }}
                        >
                          {this.state.dirty
                            ? "Save Form and Print Table"
                            : "Print Table"}
                        </button>
                      </div>
                      {this.renderTable(
                        this.homeCareRecommendation,
                        "homeCareRecommendation"
                      )}
                    </div>
                    <div className="treatment-program treatmentPro-plans">
                      <h2 className="app-heading">
                        Professional treatment plan
                      </h2>
                      {this.renderTable(this.profTreatPlan, "profTreatPlan")}
                    </div>
                    <div className="row row-space-ten">
                      <div className="col-12">
                        <div className="treatmentDetail-block mb-2">
                          <h4 className="app-heading-medium mb-0">Notes</h4>
                          <Form.Group className="form-group-app fg-app-area">
                            <Form.Control
                              value={formData.notes}
                              onChange={e =>
                                this.updateFormData(["notes"])(e.target.value)
                              }
                              as="textarea"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="pda-btns-canvas">
              <button
                className="save-button px-4"
                onClick={this.saveForm}
                disabled={
                  this.checkValidFormInputs(validInput) || saveFormLoading
                }
              >
                {saveFormLoading ? "Saving" : "Save"}
                {saveFormLoading && <SmallLoader />}
              </button>
              <button
                className="save-button px-4"
                onClick={e => {
                  e.preventDefault();
                  this.printDocument(true);
                }}
                disabled={printLoading}
              >
                {printLoading
                  ? "Saving"
                  : this.state.dirty
                  ? "Save and Print Form"
                  : "Print Form"}
                {printLoading && <SmallLoader />}
              </button>
              <div className="email-form">
                <button
                  className="save-button px-4"
                  onClick={async (e) => {
                    e.preventDefault();
                    let formDataSaved = true;
                    if (this.state.dirty) {
                      formDataSaved = await this.saveForm(false, false);
                    }
                    if (formDataSaved) {
                      this.setState({ showEmailModal: true });
                    }
                  }}
                >
                  {this.state.dirty ? "Save and Send Form as Email" : "Send Form as Email"}
                </button>
                <EmailModal
                  showEmailModal={showEmailModal}
                  handleClose={() => { this.setState({ showEmailModal: false }) }}
                  emailData={{
                    formId: this.props?.match?.params?.formId,
                    customerName: customer.name,
                    formName: 'MSR'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);

MSRTreatmentForm.propTypes = {
  customer: PropTypes.object,
  formDetails: PropTypes.object,
  formOptions: PropTypes.object
};

MSRTreatmentForm.defaultProps = {
  customer: {
    name: "Not Random Name",
    dob: "Random DOB",
    email: "Random Email",
    phone: "Random Phone",
    address: "Random Address",
    imageUrl: "Random URL",
    allergy: "Random Allergy",
    id: "Random id"
  },
  formOptions: {},
  formDetails: {
    date: null,
    skinCareSpecialist: undefined,
    concern: "",
    skinType: undefined,
    conditions: [],
    fitzpatrick: undefined,
    glogauClassification: undefined,
    homeCareProg: {
      product: [
        "Cleanser",
        "AM Moisturizer",
        "AM UV Protection",
        "Pigment Treatment",
        "Topical Acne Medication",
        "Internal Acne Medication",
        "AHA Product",
        "Retin-A/Topical Presctiption",
        "Exfoliator",
        "PM Hydrating/TX Creme"
      ],
      drugStore: {
        "0": "",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": ""
      },
      deptStore: {
        "0": "",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": ""
      },
      professional: {
        "0": "",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": ""
      },
      rx: {
        "0": "",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": ""
      }
    },
    homeCareRecommendation: {
      am: ["Step 1", "Step 2", "Step 3", "Step 4"],
      productOne: {
        "0": [],
        "1": [],
        "2": [],
        "3": []
      },
      pm: {
        "0": "",
        "1": "",
        "2": "",
        "3": ""
      },
      productTwo: {
        "0": [],
        "1": [],
        "2": [],
        "3": []
      }
    },
    profTreatPlan: {
      treatment: ["1", "2", "3", "4"],
      recommendedTreatment: {
        "0": "",
        "1": "",
        "2": "",
        "3": ""
      },
      scheduleDate: {
        "0": null,
        "1": null,
        "2": null,
        "3": null
      }
    },
    notes: ""
  }
};

export default withRouter(MSRTreatmentForm);
