import styled, { css } from "styled-components";

export const OffCanvasContainer = styled.div`
  display: flex;

  ${props =>
    props.treatmentEditOff &&
    css`
      content: "";
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
    `}
`;

export const TagStyleApply = styled.div`
  .react-tagsinput-tag {
    ${props =>
      props.color &&
      css`
        background-color: #00ffff !important;
        border: #00ffff !important;
      `}
  }
`;
