import { createAction } from "redux-actions";
import * as moment from "moment";
import { errorExtractor, compileImageUrl } from "../../Utils/misc.utils";
import api from "../../Utils/api.utils";

// import store from "../store";

export const uploadImageOnS3 = async imageUrls => {
  // ***** dummy images are not links they can cause issues, works only for blobs. Need to return in case of dummy data
  if (imageUrls !== "" && imageUrls.length) {
    // let blob = await fetch(imageUrl).then(r => r.blob());
    // const data = new FormData();
    // data.append("file", blob);
    const data = new FormData();
    // ***** update to promise.all in future
    const imageBlobs = imageUrls.map(url => {
      return fetch(url).then(r => r.blob());
    });
    const blobs = await Promise.all(imageBlobs);
    blobs.map(blob => data.append("file", blob));
    let response;
    try {
      response = await api.post("/media", data);
    } catch (err) {
      console.error(err);
    }
    return response;
  }
  return "";
};

export const addNewCustomer = dispatch => async addCustomer => {
  const { imageUrl } = addCustomer;
  let response;
  if (imageUrl) {
    response = await uploadImageOnS3([imageUrl]);
  }
  let url = "";
  if (response?.data?.media?.length) {
    url = response?.data?.media[0]?.url;
  }
  const body = {
    dob:
      addCustomer.dob && moment(addCustomer.dob).isValid()
        ? moment(addCustomer.dob).format("YYYY-MM-DD")
        : null,
    fullName: addCustomer.name,
    email: addCustomer.email || null,
    phone: addCustomer.phone,
    address: addCustomer.address
  };
  if (addCustomer.allergy !== "") {
    body.allergies = addCustomer.allergy;
  }
  if (url !== "") {
    body.avatar = url;
  }
  try {
    const res = await api.post("/customers", body);
    const _id = res.data._id;
    const avatar = res?.data?.avatar;
    dispatch(
      createAction("ADD_NEW_CUSTOMER")({
        ...addCustomer,
        id: _id,
        imageUrl: avatar ? compileImageUrl(avatar) : "",
        dob: res?.data?.dob
      })
    );
  } catch (err) {
    console.log(err);
    return errorExtractor(err);
  }
};

export const loadAllCustomers = dispatch => async sortParams => {
  dispatch(createAction("LOADING_CUSTOMERS")({}));
  let link = "/customers";
  if (Object.keys(sortParams).length) {
    link = `${link}?sortBy=${sortParams.sortBy}&order=${sortParams.order}`;
  }
  let response;
  try {
    response = await api.get(link);
  } catch (err) {
    dispatch(createAction("LOADING_CUSTOMERS_UNSUCCESSFUL")({ err }));
    return;
  }
  if (response.data.length) {
    const customers = response.data.map(customer => {
      return {
        dob: customer.dob,
        name: customer.fullName,
        email: customer.email,
        phone: customer.phone,
        // address: customer.address,
        allergy: customer.allergies,
        imageUrl: customer.avatar ? compileImageUrl(customer.avatar) : "",
        id: customer._id,
        lastVisited: customer.lastVisit
      };
    });
    dispatch(createAction("LOADING_CUSTOMERS_SUCCESSFUL")({ customers }));
  }
};

export const deleteCustomer = dispatch => async customerId => {
  try {
    await api.delete(`/customers/${customerId}`);
    dispatch(createAction("DELETE_CUSTOMER")({ customerId }));
  } catch (e) {
    console.error(e);
    return errorExtractor(e);
  }
};
