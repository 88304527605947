import { useState } from "react";
import api from "../../Utils/api.utils";

export const useLogin = () => {
  const [res, setRes] = useState({
    data: null,
    loading: false,
    error: null,
    hasData: false
  });

  const login = async ({ username, password }) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const response = await api.post("/auth/login", { username, password });
      setRes({
        data: response.data,
        loading: false,
        error: null,
        hasData: true
      });
    } catch (error) {
      console.error(error);
      setRes({
        data: null,
        loading: false,
        error,
        hasData: true
      });
    }
  };

  return { res, login };
};

export const logout = async () => {
  return await api.delete("/auth/logout");
};
