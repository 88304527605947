import { createAction } from "redux-actions";
import api from "../../Utils/api.utils";
import * as moment from "moment";
import { errorExtractor, showNotification } from "../../Utils/misc.utils";

export const loadPDAFormData = dispatch => async (id, redirect) => {
  dispatch(createAction("LOADING_PDA_FORM_DATA")({}));
  let link = `/forms/${id}`;
  let response;
  try {
    response = await api.get(link);
    if (response.data && response.data.pda) {
      const { _id, ...remainingData } = response.data.pda;
      const parsedFormData = parsePDAFormDataForRedux(remainingData);
      dispatch(
        createAction("LOADING_PDA_FORM_DATA_SUCCESSFUL")({
          formDetails: { ...parsedFormData }
        })
      );
      return;
    }
    dispatch(createAction("LOADED_PDA_FORM_WITH_NO_DATA")({}));
  } catch (err) {
    dispatch(createAction("LOADING_PDA_FORM_DATA_UNSUCCESSFUL")({ err }));
    if (err?.response?.status >= 400 && err?.response?.status < 500) {
      showNotification("error", "Fetch Form Error", errorExtractor(err));
      redirect("/customer");
    }
    console.log(err);
  }
};

export const addPDAFormData = dispatch => async (id, formData) => {
  let link = `/forms/${id}`;
  let response;
  const body = {
    date: moment(formData.date).format("YYYY-MM-DD"),
    treatmentPerformed: formData.treatmentPerformed.map(obj => obj.label),
    priorCosmeticProcedures: formData.priorCosmeticProcedures,
    fitzpatrickType: formData.fitzpatrickType
      ? formData.fitzpatrickType.label
      : "",
    glogauClassification: formData.glogauClassification
      ? formData.glogauClassification.label
      : "",
    skinType: formData.skinType ? formData.skinType.label : "",
    conditions: formData.conditions.map(obj => obj.label),
    faceCharting: formData.faceCharting,
    SubDermisThickness: formData.SubDermisThickness,
    PatchTestPerformed: formData.PatchTestPerformed,
    FineLinesAndWrinkles: {
      wrinkleAssessment: formData.FineLinesAndWrinkles.wrinkleAssessment
        ? formData.FineLinesAndWrinkles.wrinkleAssessment.label
        : "",
      elastosisAssessment: formData.FineLinesAndWrinkles.elastosisAssessment
        ? formData.FineLinesAndWrinkles.elastosisAssessment.label
        : ""
    },
    Pigmentation: {
      PhotoDamage: formData.Pigmentation.PhotoDamage
        ? formData.Pigmentation.PhotoDamage.label
        : "",
      PigmentDamageInitiatedFrom: formData.Pigmentation
        .PigmentDamageInitiatedFrom
        ? formData.Pigmentation.PigmentDamageInitiatedFrom.label
        : ""
    },
    Acne: {
      grade: formData.Acne.grade ? formData.Acne.grade.label : "",
      lesionsCount: {
        leftSide: formData.Acne.lesionsCount.leftSide,
        rightSide: formData.Acne.lesionsCount.rightSide
      },
      LesionsIdentified: {
        openComedones: formData.Acne.LesionsIdentified.openComedones,
        openPapules: formData.Acne.LesionsIdentified.openPapules,
        closedComedones: formData.Acne.LesionsIdentified.closedComedones,
        closedPapules: formData.Acne.LesionsIdentified.closedPapules,
        nodules: formData.Acne.LesionsIdentified.nodules,
        cysts: formData.Acne.LesionsIdentified.cysts,
        milia: formData.Acne.LesionsIdentified.milia
      }
    },
    Sensitivity: {
      rosaceaStage: formData.Sensitivity.rosaceaStage.map(obj => obj.label),
      skinType: formData.Sensitivity.skinType
        ? formData.Sensitivity.skinType.label
        : "",
      location: formData.Sensitivity.location.map(obj => obj.label),
      hyperSensitive: formData.Sensitivity.hyperSensitive,
      skinVisiblePeeling: formData.Sensitivity.skinVisiblePeeling,
      openWounds: formData.Sensitivity.openWounds
    },
    notes: formData.notes,
    samplesGiven: formData.samplesGiven,
    productsPurchased: formData.productsPurchased.map(obj => obj.label),
    ProgressNotes: formData.ProgressNotes,
    assessment: formData.assessment.map(obj => obj.label),
    plan: formData.plan.map(obj => obj.label),
    isVerbalConsent: formData.isVerbalConsent,
    isWrittenConsent: formData.isWrittenConsent,
    isVerbalInstructions: formData.isVerbalInstructions,
    isWrittenInstructions: formData.isWrittenInstructions,
    signatureAndDate: {
      signature: formData.signatureAndDate.signature,
      date: moment().format("YYYY-MM-DD")
    }
  };
  try {
    response = await api.put(link, { pda: body });
    if (response.data && response.data.pda) {
      const { _id, ...remainingData } = response.data.pda;
      const parsedFormData = parsePDAFormDataForRedux(remainingData);
      dispatch(
        createAction("LOADING_PDA_FORM_DATA_SUCCESSFUL")({
          formDetails: { ...parsedFormData }
        })
      );
    }
  } catch (err) {
    console.log(err);
    return errorExtractor;
  }
};

const parsePDAFormDataForRedux = formData => {
  return {
    date:
      formData?.date && moment(formData.date).isValid()
        ? moment(formData?.date).toDate()
        : moment().toDate(),
    treatmentPerformed: formData.treatmentPerformed.map(obj => {
      return { label: obj, value: obj };
    }),
    priorCosmeticProcedures: formData.priorCosmeticProcedures,
    fitzpatrickType: formData.fitzpatrickType
      ? { label: formData.fitzpatrickType, value: formData.fitzpatrickType }
      : "",
    glogauClassification: formData.glogauClassification
      ? {
          label: formData.glogauClassification,
          value: formData.glogauClassification
        }
      : "",
    skinType: formData.skinType
      ? { label: formData.skinType, value: formData.skinType }
      : "",
    conditions: formData.conditions.map(obj => {
      return { label: obj, value: obj };
    }),
    faceCharting: formData.faceCharting,
    SubDermisThickness: formData.SubDermisThickness,
    PatchTestPerformed: formData.PatchTestPerformed,
    FineLinesAndWrinkles: {
      wrinkleAssessment: formData.FineLinesAndWrinkles.wrinkleAssessment
        ? {
            label: formData.FineLinesAndWrinkles.wrinkleAssessment,
            value: formData.FineLinesAndWrinkles.wrinkleAssessment
          }
        : "",
      elastosisAssessment: formData.FineLinesAndWrinkles.elastosisAssessment
        ? {
            label: formData.FineLinesAndWrinkles.elastosisAssessment,
            value: formData.FineLinesAndWrinkles.elastosisAssessment
          }
        : ""
    },
    Pigmentation: {
      PhotoDamage: formData.Pigmentation.PhotoDamage
        ? {
            label: formData.Pigmentation.PhotoDamage,
            value: formData.Pigmentation.PhotoDamage
          }
        : "",
      PigmentDamageInitiatedFrom: formData.Pigmentation
        .PigmentDamageInitiatedFrom
        ? {
            label: formData.Pigmentation.PigmentDamageInitiatedFrom,
            value: formData.Pigmentation.PigmentDamageInitiatedFrom
          }
        : ""
    },
    Acne: {
      grade: formData.Acne.grade
        ? { label: formData.Acne.grade, value: formData.Acne.grade }
        : "",
      lesionsCount: {
        leftSide: formData.Acne.lesionsCount.leftSide,
        rightSide: formData.Acne.lesionsCount.rightSide
      },
      LesionsIdentified: {
        openComedones: formData.Acne.LesionsIdentified.openComedones,
        openPapules: formData.Acne.LesionsIdentified.openPapules,
        closedComedones: formData.Acne.LesionsIdentified.closedComedones,
        closedPapules: formData.Acne.LesionsIdentified.closedPapules,
        nodules: formData.Acne.LesionsIdentified.nodules,
        cysts: formData.Acne.LesionsIdentified.cysts,
        milia: formData.Acne.LesionsIdentified.milia
      }
    },
    Sensitivity: {
      rosaceaStage: formData.Sensitivity.rosaceaStage.map(obj => {
        return { label: obj, value: obj };
      }),
      skinType: formData.Sensitivity.skinType
        ? {
            label: formData.Sensitivity.skinType,
            value: formData.Sensitivity.skinType
          }
        : "",
      location: formData.Sensitivity.location.map(obj => {
        return { label: obj, value: obj };
      }),
      hyperSensitive: formData.Sensitivity.hyperSensitive,
      skinVisiblePeeling: formData.Sensitivity.skinVisiblePeeling,
      openWounds: formData.Sensitivity.openWounds
    },
    notes: formData.notes,
    samplesGiven: formData.samplesGiven,
    productsPurchased: formData.productsPurchased.map(obj => {
      return { label: obj, value: obj };
    }),
    ProgressNotes: formData.ProgressNotes,
    assessment: formData.assessment.map(obj => {
      return { label: obj, value: obj };
    }),
    plan: formData.plan.map(obj => {
      return { label: obj, value: obj };
    }),
    isVerbalConsent: formData.isVerbalConsent,
    isWrittenConsent: formData.isWrittenConsent,
    isVerbalInstructions: formData.isVerbalInstructions,
    isWrittenInstructions: formData.isWrittenInstructions,
    signatureAndDate: {
      signature: formData.signatureAndDate.signature,
      date: formData.signatureAndDate.date
        ? moment(formData.signatureAndDate.date).format("MM/DD/YYYY")
        : ""
    }
  };
};
