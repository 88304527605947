import React from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";

export default class DateSelector extends React.Component {
  render() {
    return (
      <div className="datepicker-group">
        <span className="red-circle">
          <i className="icon-arrow-down"></i>
        </span>
        <DatePicker
          selected={this.props.value}
          onChange={this.props.handleChange}
          dateFormat="MM/dd/yyyy"
          onBlur={() => this.props.onBlur(this.props.field)}
          placeholderText="mm/dd/yyyy"
        />
      </div>
    );
  }
}

DateSelector.propTypes = {
  handleChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.object,
  field: PropTypes.string
};

DateSelector.defaultProps = {
  handleChange: () => {},
  onBlur: () => {},
  value: null,
  field: "dob"
};
