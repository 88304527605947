const initialState = {
  loading: false,
  error: null,
  profileInfo: {
    name: "",
    dob: "",
    email: "",
    phone: "",
    address: "",
    imageUrl: "",
    allergy: "",
    id: ""
  }
};

const ProfileInfo = (state = initialState, action) => {
  if (action.type === "LOADING_PROFILE_INFO") {
    return {
      loading: true,
      err: null,
      profileInfo: {
        name: "",
        dob: "",
        email: "",
        phone: "",
        address: "",
        imageUrl: "",
        allergy: "",
        id: ""
      }
    };
  }
  if (
    action.type === "LOADING_PROFILE_INFO_SUCCESSFUL" ||
    action.type === "UPDATE_PROFILE_INFO"
  ) {
    const { payload } = action;
    return {
      loading: false,
      err: null,
      profileInfo: { ...payload.profileInfo }
    };
  }
  if (action.type === "LOADING_PROFILE_INFO_UNSUCCESSFUL") {
    const { payload } = action;
    return {
      loading: false,
      err: payload.err,
      profileInfo: {
        name: "",
        dob: "",
        email: "",
        phone: "",
        address: "",
        imageUrl: "",
        allergy: "",
        id: ""
      }
    };
  }
  if (action.type === "CLEAR_PROFILE_INFO") {
    return initialState;
  }
  return state;
};

export default ProfileInfo;
