import { combineReducers } from "redux";

import Sidebar from "./sidebar";
import Customers from "./customer";
import ProfileInfo from "./profileInfo";
import Treatments from "./treatment";
import MSRFormData from "./msrFormData";
import PDAFormData from "./pdaFormData";
import FormOptions from "./formOptions";
import RouteStack from "./route";
import User from "./user";

export default combineReducers({
  Sidebar,
  ProfileInfo,
  Treatments,
  Customers,
  MSRFormData,
  FormOptions,
  PDAFormData,
  RouteStack,
  User,
});
