import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import {
  ALLOWED_IMAGE_TYPES,
  DEFAULT_IMAGE,
  IMAGE_SIZE_LIMIT
} from "../../constants";
import { showNotification } from "../../Utils/misc.utils";
import SmallLoader from "../Common/small.loader";

const UserModal = class UserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: "",
      name: "",
      updateLoading: false
    };
  }

  setUserName = val => this.setState({ name: val });
  setUserImage = async e => {
    if (e.target.files) {
      const file = e?.target?.files[0];
      if (file?.size < IMAGE_SIZE_LIMIT) {
        if (ALLOWED_IMAGE_TYPES.includes(file?.type)) {
          this.setState({
            avatar: URL.createObjectURL(file)
          });
        } else {
          alert("Allowed image types are jpg, png and svg");
        }
      } else {
        alert(`Image Size Limit Exceeded (10 MB)`);
      }
    }
  };

  componentDidMount() {
    this.setState({ avatar: this.props.avatar, name: this.props.name });
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({ avatar: this.props.avatar, name: this.props.name });
    }
  }

  updateUser = async ({ name, avatar }) => {
    this.setState({ updateLoading: true });
    const err = await this.props.updateUserInfo({ name, avatar });
    this.setState({ updateLoading: false });
    if (err) {
      showNotification("error", "Error", err);
    } else {
      showNotification("success", "Success", "Updated user successfully");
      this.props.handleClose();
    }
  };

  render() {
    const { show } = this.props;
    const { name, avatar, updateLoading: loading } = this.state;
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-modal profile-modal"
        scrollable
        show={show}
        onHide={this.props.handleClose}
        backdrop="static"
      >
        <Modal.Body>
          <Form className="w-100">
            <div className="customer-profile profileModal-main">
              <div className="modal-canvas">
                <div className="cp-inner mb-0">
                  <div className="user-profile user-profile-lg d-flex align-items-center mb-0">
                    <div className="user-img-canvas">
                      <label className="customer-img-upload bg-primary">
                        {avatar ? (
                          <div className="user-img">
                            <img src={avatar} alt="User Img" />
                          </div>
                        ) : (
                          <React.Fragment>
                            <input
                              type="file"
                              onChange={this.setUserImage}
                              accept="image/*"
                            />
                            <i className="icon-edit"></i>
                          </React.Fragment>
                        )}
                      </label>
                      {avatar && (
                        <span
                          className="user-cross"
                          onClick={() =>
                            this.setState({
                              avatar: ""
                            })
                          }
                        >
                          <i className="icon-cross"></i>
                        </span>
                      )}
                    </div>
                    <div className="user-detail">
                      <Form.Group className="modal-group modalGroup-name mb-0">
                        <Form.Control
                          value={name}
                          type="text"
                          placeholder="Jane Doe"
                          onChange={e => this.setUserName(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  className="btn addCustomer-btn addCustomer-btn-complete"
                  onClick={e => this.updateUser({ name, avatar })}
                  disabled={loading}
                >
                  {loading ? "Updating" : "Update"}
                  {loading && <SmallLoader />}
                </Button>
              </div>
              <Button
                onClick={this.props.handleClose}
                className="btn close-btn close-circle"
              >
                <i className="icon-cross"></i>
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
};
// );

UserModal.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  updateUserInfo: PropTypes.func
};

UserModal.defaultProps = {
  name: "",
  avatar: DEFAULT_IMAGE,
  show: false,
  handleClose: () => {},
  updateUserInfo: () => {}
};
export default UserModal;
