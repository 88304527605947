import { createAction } from "redux-actions";
import api from "../../Utils/api.utils";
import * as moment from "moment";
import * as constants from "../../constants";
import { uploadImageOnS3 } from "./customer";
import { errorExtractor, showNotification } from "../../Utils/misc.utils";
import env from "../../Utils/env.utils";
import { ALLOWED_IMAGE_TYPES } from "../../constants";

export const addNewTreatment = dispatch => async (
  addTreatment,
  customerId,
  number
) => {
  const { imageUrls } = addTreatment;
  const response = await uploadImageOnS3(imageUrls);
  const body = {
    name: addTreatment.treatmentType,
    customer: customerId,
    // media: response && "media" in response.data && response.data.media.length &&,
    date: moment(addTreatment.date).format("YYYY-MM-DD"),
    forms: addTreatment.forms,
    number
  };
  if (
    response &&
    response.data &&
    "media" in response.data &&
    response.data.media.length
  ) {
    body.media = response.data.media.map(image => {
      return {
        title: image.name,
        url: image.url,
        mimeType: image.type
      };
    });
  }
  try {
    const res = await api.post("/treatments", body);
    if (res.data) {
      const urls = [];
      let imageObjs = [];
      if (res.data.media && res.data.media.length) {
        imageObjs = res.data.media;
        res.data.media.map(elem => {
          urls.push(env.mediaBaseUrl + elem.url);
        });
      }
      const treatment = {
        id: res.data._id,
        treatmentType: res.data.name,
        date: res.data.date,
        imageUrls: urls,
        imageObjs: imageObjs,
        num: res.data.number,
        forms: res.data.forms
      };
      dispatch(createAction("ADD_NEW_TREATMENT")(treatment));
    }
  } catch (err) {
    console.log(err);
    return errorExtractor(err);
  }
};

// ***** Changing position could cause troubles
const extractImageUrls = obj => {
  const urls = [];
  if (obj.media && obj.media.length) {
    obj.media.map(elem => {
      urls.push(env.mediaBaseUrl + elem.url);
    });
  }
  return urls;
};

const extractImageObjs = obj => {
  if (obj.media && obj.media.length) {
    return obj.media;
  }
  return [];
};

export const loadAllTreatments = dispatch => async (id, sortParams = {}) => {
  dispatch(createAction("LOADING_TREATMENTS")({}));
  let link = `/treatments/${id}`;
  if (Object.keys(sortParams).length) {
    link = `${link}?sortBy=${sortParams.sortBy}&order=${sortParams.order}`;
  }
  let response;
  try {
    response = await api.get(link);
  } catch (err) {
    dispatch(createAction("LOADING_TREATMENTS_UNSUCCESSFUL")({ err }));
    return;
  }
  if (response.data.length) {
    const treatments = response.data.map(treatment => {
      return {
        date: treatment.date,
        treatmentType: treatment.name,
        email: treatment.email,
        forms: treatment.forms,
        imageUrls: extractImageUrls(treatment),
        imageObjs: extractImageObjs(treatment),
        id: treatment._id,
        num: treatment.number
      };
    });
    dispatch(createAction("LOADING_TREATMENTS_SUCCESSFUL")({ treatments }));
  }
};

export const addNewForm = dispatch => async (treatmentId, type) => {
  let link = `/treatments/${treatmentId}`;
  try {
    let response = await api.patch(link, { forms: [type] });
    if (response.data) {
      const { forms } = response.data;
      showNotification(
        "success",
        "Add New Form Success",
        "Added a new form successfully"
      );
      dispatch(
        createAction("ADD_NEW_TREATMENT_FORM_OR_IMAGE")({
          id: treatmentId,
          data: forms,
          type: "forms"
        })
      );
    }
  } catch (err) {
    console.log(err);
    showNotification("error", "Add New Form Error", errorExtractor(err));
    return;
  }
};

export const addNewImage = dispatch => async (treatmentId, e) => {
  const { files } = e.target;
  let link = `/treatments/${treatmentId}`;
  if (files) {
    if (files[0] && files[0].size > constants.IMAGE_SIZE_LIMIT) {
      alert("Image Size Limit Exceeded (10MB)");
      return;
    }
    if (files[0] && !ALLOWED_IMAGE_TYPES.includes(files[0].type)) {
      alert("Allowed image types are jpg, png and svg");
      return;
    }
    const imageUrl = URL.createObjectURL(files[0]);
    const response = await uploadImageOnS3([imageUrl]);
    if (
      response &&
      response.data &&
      "media" in response.data &&
      response.data.media.length
    ) {
      try {
        let res = await api.patch(link, {
          media: [
            {
              title: response.data.media[0].name,
              url: response.data.media[0].url,
              mimeType: response.data.media[0].type
            }
          ]
        });
        if (res.data) {
          const media = extractImageUrls(res.data);
          const imageObjs = extractImageObjs(res.data);
          showNotification(
            "success",
            "Add New Image Success",
            "Added a new image successfully"
          );
          dispatch(
            createAction("ADD_NEW_TREATMENT_FORM_OR_IMAGE")({
              id: treatmentId,
              data: media,
              type: "imageUrls",
              imageObjs: imageObjs
            })
          );
        }
      } catch (err) {
        console.error(err);
        showNotification("error", "Add New Image Error", errorExtractor(err));
      }
    } else {
      showNotification("error", "Add New Image Error", "Upload Failed");
    }
  }
};

export const deleteImage = dispatch => async (
  treatmentId,
  imageId,
  index,
  photoIndex
) => {
  let link = `/treatments/${treatmentId}/image/${imageId}`;
  try {
    let response = await api.delete(link);
    if (response) {
      showNotification("success", "Success", "Image deleted successfully");
      dispatch(
        createAction("DELETE_TREATMENT_IMAGE")({
          index: index,
          photoIndex: photoIndex
        })
      );
      document.body.click();
    }
  } catch (err) {
    console.error(err);
    showNotification("error", "Error", errorExtractor(err));
  }
};

export const deleteForm = dispatch => async (
  treatmentId,
  formId,
  index,
  formIndex
) => {
  let link = `/treatments/${treatmentId}/form/${formId}`;
  try {
    let response = await api.delete(link);
    if (response) {
      showNotification("success", "Success", "Form deleted successfully");
      dispatch(
        createAction("DELETE_TREATMENT_FORM")({
          index: index,
          formIndex: formIndex
        })
      );
      document.body.click();
    }
  } catch (err) {
    console.error(err);
    showNotification("error", "Error", errorExtractor(err));
  }
};

export const deleteTreatment = dispatch => async treatmentId => {
  try {
    await api.delete(`/treatments/${treatmentId}`);
    dispatch(createAction("DELETE_TREATMENT")({ treatmentId }));
    showNotification("success", "Success", "Treatment deleted successfully");
    document.body.click();
  } catch (err) {
    console.error(err);
    showNotification("error", "Error", errorExtractor(err));
  }
};
