import { createAction } from "redux-actions";
import moment from "moment";
import api from "../../Utils/api.utils";
import { errorExtractor, showNotification } from "../../Utils/misc.utils";

export const getMSRForm = dispatch => async (formId, formOptions, redirect) => {
  dispatch(createAction("LOADING_MSR_FORM_DATA")(undefined));
  try {
    const response = await api.get(`/forms/${formId}`);
    dispatch(
      createAction("LOADING_MSR_FORM_DATA_SUCCESSFUL")(
        convertFormDataIntoState(response.data.msr, formOptions)
      )
    );
    return { error: null, data: response.data };
  } catch (e) {
    console.error(e);
    dispatch(createAction("LOADING_MSR_FORM_DATA_UNSUCCESSFUL")(e));
    if (e?.response?.status >= 400 && e?.response?.status < 500) {
      showNotification("error", "Fetch Form Error", errorExtractor(e));
      redirect("/customer");
      return { error: null, data: null };
    }
    return { error: errorExtractor(e), data: null };
  }
};

export const putMSRForm = dispatch => async (formId, formData, formOptions) => {
  try {
    const response = await api.put(
      `/forms/${formId}`,
      convertStateIntoFormData(formData)
    );
    dispatch(
      createAction("UPDATE_MSR_FORM_DATA")(
        convertFormDataIntoState(response.data.msr, formOptions)
      )
    );
    return { error: null, data: response.data };
  } catch (e) {
    console.error(e);
    return { error: errorExtractor(e), data: null };
  }
};

export const convertFormDataIntoState = (data, formOptions) => {
  // console.log(data, formOptions);
  // debugger;
  if (data) {
    const professionalTreatmentPlanOne = data?.professionalTreatmentPlan?.find(
      obj => obj.number === 1
    );
    const professionalTreatmentPlanTwo = data?.professionalTreatmentPlan?.find(
      obj => obj.number === 2
    );
    const professionalTreatmentPlanThree = data?.professionalTreatmentPlan?.find(
      obj => obj.number === 3
    );
    const professionalTreatmentPlanFour = data?.professionalTreatmentPlan?.find(
      obj => obj.number === 4
    );
    return {
      date:
        data?.date && moment(data.date).isValid()
          ? moment(data?.date).toDate()
          : moment().toDate(),
      skinCareSpecialist: formOptions?.skinCareSpecialists?.find(
        obj => obj.value === data?.skinCareSpecialist
      ),
      concern: data?.concern,
      fitzpatrick: formOptions?.fitzpatrick?.find(
        obj => obj.value === data?.fitzpatrickType
      ),
      glogauClassification: formOptions?.glogauClassification?.find(
        obj => obj.value === data?.glogauClassification
      ),
      skinType: formOptions?.skinType?.find(
        obj => obj.value === data?.skinType
      ),
      conditions: formOptions?.conditions?.filter(obj =>
        data?.conditions?.includes(obj.value)
      ),
      homeCareProg: {
        product: [
          "Cleanser",
          "AM Moisturizer",
          "AM UV Protection",
          "Pigment Treatment",
          "Topical Acne Medication",
          "Internal Acne Medication",
          "AHA Product",
          "Retin-A/Topical Presctiption",
          "Exfoliator",
          "PM Hydrating/TX Creme"
        ],
        drugStore: {
          "0": data?.cleanser?.drugStore || "",
          "1": data?.AMMoisturizer?.drugStore || "",
          "2": data?.AMUVProtection?.drugStore || "",
          "3": data?.pigmentTreatment?.drugStore || "",
          "4": data?.topicalAcneMedication?.drugStore || "",
          "5": data?.internalAcneMedication?.drugStore || "",
          "6": data?.AHAProduct?.drugStore || "",
          "7": data?.topicalPresctiption?.drugStore || "",
          "8": data?.exfoliator?.drugStore || "",
          "9": data?.PMHydrating?.drugStore || ""
        },
        deptStore: {
          "0": data?.cleanser?.deptStore || "",
          "1": data?.AMMoisturizer?.deptStore || "",
          "2": data?.AMUVProtection?.deptStore || "",
          "3": data?.pigmentTreatment?.deptStore || "",
          "4": data?.topicalAcneMedication?.deptStore || "",
          "5": data?.internalAcneMedication?.deptStore || "",
          "6": data?.AHAProduct?.deptStore || "",
          "7": data?.topicalPresctiption?.deptStore || "",
          "8": data?.exfoliator?.deptStore || "",
          "9": data?.PMHydrating?.deptStore || ""
        },
        professional: {
          "0": data?.cleanser?.professional || "",
          "1": data?.AMMoisturizer?.professional || "",
          "2": data?.AMUVProtection?.professional || "",
          "3": data?.pigmentTreatment?.professional || "",
          "4": data?.topicalAcneMedication?.professional || "",
          "5": data?.internalAcneMedication?.professional || "",
          "6": data?.AHAProduct?.professional || "",
          "7": data?.topicalPresctiption?.professional || "",
          "8": data?.exfoliator?.professional || "",
          "9": data?.PMHydrating?.professional || ""
        },
        rx: {
          "0": data?.cleanser?.RX || "",
          "1": data?.AMMoisturizer?.RX || "",
          "2": data?.AMUVProtection?.RX || "",
          "3": data?.pigmentTreatment?.RX || "",
          "4": data?.topicalAcneMedication?.RX || "",
          "5": data?.internalAcneMedication?.RX || "",
          "6": data?.AHAProduct?.RX || "",
          "7": data?.topicalPresctiption?.RX || "",
          "8": data?.exfoliator?.RX || "",
          "9": data?.PMHydrating?.RX || ""
        }
      },
      homeCareRecommendation: {
        am: ["Step 1", "Step 2", "Step 3", "Step 4"],
        productOne: {
          "0": formOptions?.products?.filter(obj =>
            data?.step1AM?.includes(obj.value)
          ),
          "1": formOptions?.products?.filter(obj =>
            data?.step2AM?.includes(obj.value)
          ),
          "2": formOptions?.products?.filter(obj =>
            data?.step3AM?.includes(obj.value)
          ),
          "3": formOptions?.products?.filter(obj =>
            data?.step4AM?.includes(obj.value)
          )
        },
        pm: {
          "0": "",
          "1": "",
          "2": "",
          "3": ""
        },
        productTwo: {
          "0": formOptions?.products?.filter(obj =>
            data?.step1PM?.includes(obj.value)
          ),
          "1": formOptions?.products?.filter(obj =>
            data?.step2PM?.includes(obj.value)
          ),
          "2": formOptions?.products?.filter(obj =>
            data?.step3PM?.includes(obj.value)
          ),
          "3": formOptions?.products?.filter(obj =>
            data?.step4PM?.includes(obj.value)
          )
        }
      },
      profTreatPlan: {
        treatment: ["1", "2", "3", "4"],
        recommendedTreatment: {
          "0":
            professionalTreatmentPlanOne &&
            professionalTreatmentPlanOne.recommendedTreatment
              ? professionalTreatmentPlanOne.recommendedTreatment
              : "",
          "1":
            professionalTreatmentPlanTwo &&
            professionalTreatmentPlanTwo.recommendedTreatment
              ? professionalTreatmentPlanTwo.recommendedTreatment
              : "",
          "2":
            professionalTreatmentPlanThree &&
            professionalTreatmentPlanThree.recommendedTreatment
              ? professionalTreatmentPlanThree.recommendedTreatment
              : "",
          "3":
            professionalTreatmentPlanFour &&
            professionalTreatmentPlanFour.recommendedTreatment
              ? professionalTreatmentPlanFour.recommendedTreatment
              : ""
        },
        scheduleDate: {
          "0":
            professionalTreatmentPlanOne && professionalTreatmentPlanOne.date
              ? moment(professionalTreatmentPlanOne.date).toDate()
              : null,
          "1":
            professionalTreatmentPlanTwo && professionalTreatmentPlanTwo.date
              ? moment(professionalTreatmentPlanTwo.date).toDate()
              : null,
          "2":
            professionalTreatmentPlanThree &&
            professionalTreatmentPlanThree.date
              ? moment(professionalTreatmentPlanThree.date).toDate()
              : null,
          "3":
            professionalTreatmentPlanFour && professionalTreatmentPlanFour.date
              ? moment(professionalTreatmentPlanFour.date).toDate()
              : null
        }
      },
      notes: data?.notes
    };
  } else {
    return undefined;
  }
};

export const convertStateIntoFormData = data => {
  const professionalTreatmentPlan = [];
  for (let i = 0; i < 4; i++) {
    const selectedDate = moment(data?.profTreatPlan?.scheduleDate[i]);
    professionalTreatmentPlan.push({
      number: data?.profTreatPlan?.treatment[i],
      recommendedTreatment: data?.profTreatPlan?.recommendedTreatment[i] || "",
      date: selectedDate.isValid() ? selectedDate.format("YYYY-MM-DD") : null
    });
  }
  return {
    msr: {
      notes: data?.notes,
      date: moment(data?.date).format("YYYY-MM-DD"),
      skinCareSpecialist: data?.skinCareSpecialist?.value?.toString(),
      concern: data?.concern,
      fitzpatrickType: data?.fitzpatrick?.value?.toString(),
      glogauClassification: data?.glogauClassification?.value?.toString(),
      skinType: data?.skinType?.value?.toString(),
      conditions: data?.conditions?.map(obj => obj.value?.toString()),
      cleanser: {
        drugStore: data?.homeCareProg?.drugStore[0] || "",
        deptStore: data?.homeCareProg?.deptStore[0] || "",
        professional: data?.homeCareProg?.professional[0] || "",
        RX: data?.homeCareProg?.rx[0] || ""
      },
      AMMoisturizer: {
        drugStore: data?.homeCareProg?.drugStore[1] || "",
        deptStore: data?.homeCareProg?.deptStore[1] || "",
        professional: data?.homeCareProg?.professional[1] || "",
        RX: data?.homeCareProg?.rx[1] || ""
      },
      AMUVProtection: {
        drugStore: data?.homeCareProg?.drugStore[2] || "",
        deptStore: data?.homeCareProg?.deptStore[2] || "",
        professional: data?.homeCareProg?.professional[2] || "",
        RX: data?.homeCareProg?.rx[2] || ""
      },
      pigmentTreatment: {
        drugStore: data?.homeCareProg?.drugStore[3] || "",
        deptStore: data?.homeCareProg?.deptStore[3] || "",
        professional: data?.homeCareProg?.professional[3] || "",
        RX: data?.homeCareProg?.rx[3] || ""
      },
      topicalAcneMedication: {
        drugStore: data?.homeCareProg?.drugStore[4] || "",
        deptStore: data?.homeCareProg?.deptStore[4] || "",
        professional: data?.homeCareProg?.professional[4] || "",
        RX: data?.homeCareProg?.rx[4] || ""
      },
      internalAcneMedication: {
        drugStore: data?.homeCareProg?.drugStore[5] || "",
        deptStore: data?.homeCareProg?.deptStore[5] || "",
        professional: data?.homeCareProg?.professional[5] || "",
        RX: data?.homeCareProg?.rx[5] || ""
      },
      AHAProduct: {
        drugStore: data?.homeCareProg?.drugStore[6] || "",
        deptStore: data?.homeCareProg?.deptStore[6] || "",
        professional: data?.homeCareProg?.professional[6] || "",
        RX: data?.homeCareProg?.rx[6] || ""
      },
      topicalPresctiption: {
        drugStore: data?.homeCareProg?.drugStore[7] || "",
        deptStore: data?.homeCareProg?.deptStore[7] || "",
        professional: data?.homeCareProg?.professional[7] || "",
        RX: data?.homeCareProg?.rx[7] || ""
      },
      exfoliator: {
        drugStore: data?.homeCareProg?.drugStore[8] || "",
        deptStore: data?.homeCareProg?.deptStore[8] || "",
        professional: data?.homeCareProg?.professional[8] || "",
        RX: data?.homeCareProg?.rx[8] || ""
      },
      PMHydrating: {
        drugStore: data?.homeCareProg?.drugStore[9] || "",
        deptStore: data?.homeCareProg?.deptStore[9] || "",
        professional: data?.homeCareProg?.professional[9] || "",
        RX: data?.homeCareProg?.rx[9] || ""
      },
      step1AM: data?.homeCareRecommendation?.productOne[0]?.map(
        obj => obj.value
      ),
      step2AM: data?.homeCareRecommendation?.productOne[1]?.map(
        obj => obj.value
      ),
      step3AM: data?.homeCareRecommendation?.productOne[2]?.map(
        obj => obj.value
      ),
      step4AM: data?.homeCareRecommendation?.productOne[3]?.map(
        obj => obj.value
      ),
      step1PM: data?.homeCareRecommendation?.productTwo[0]?.map(
        obj => obj.value
      ),
      step2PM: data?.homeCareRecommendation?.productTwo[1]?.map(
        obj => obj.value
      ),
      step3PM: data?.homeCareRecommendation?.productTwo[2]?.map(
        obj => obj.value
      ),
      step4PM: data?.homeCareRecommendation?.productTwo[3]?.map(
        obj => obj.value
      ),
      professionalTreatmentPlan
    }
  };
};
