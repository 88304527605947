import React, { Component } from "react";

import PropTypes from "prop-types";

import Sidebar from "../../Components/Sidebar";
import NavigationButtons from "../../Components/NavigationButtons";
import TagInput from "../../Components/SelectComponents/TagInput";
import { connect } from "react-redux";
import { createAction } from "redux-actions";
import {
  loadFormOptions,
  updateFormOptions,
} from "../../Redux/actions/formOptions";
import { SketchPicker } from "react-color";

const mapStateToProps = (state) => {
  return { formOptions: state.FormOptions.options };
};

const SettingsForm = connect(mapStateToProps)(
  class SettingsForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        productColor: "#f2594f",
        show: false,
      };
    }

    extractLables = (key) => {
      const { formOptions } = this.props;
      const options = formOptions[key];
      return options.map((elem) => elem.label);
    };

    updateOptions = (option) => (values, type) => {
      const { dispatch } = this.props;
      if (type === "add") {
        dispatch(
          createAction("ADD_FORM_OPTION")({
            option,
            values,
            ...(option === "products" && { color: this.state.productColor }),
          })
        );
      }
      if (type === "remove") {
        dispatch(createAction("DELETE_FORM_OPTION")({ option, values }));
      }
    };

    setColorPicker = () => this.setState({ show: !this.state.show });

    componentDidMount() {
      const { dispatch } = this.props;
      loadFormOptions(dispatch);
    }

    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(createAction("CLEAR_FORM_OPTIONS")({}));
    }

    changeColor = (colorObj) => this.setState({ productColor: colorObj.hex });

    render() {
      const { formOptions } = this.props;
      return (
        <div className="off-canvas-container">
          <Sidebar />
          <div className="main-content setting-content">
            <h4 className="app-heading-medium">Settings</h4>
            <NavigationButtons />
            <div className="settings-container">
              <div className="settings-block">
                <h6>Our Services</h6>
                <TagInput
                  tags={this.extractLables("treatment")}
                  length={formOptions["treatment"].length}
                  updateOptions={this.updateOptions("treatment")}
                />
              </div>
              <div className="settings-block">
                <h6>Pigment damage initiated from</h6>
                <TagInput
                  tags={this.extractLables("pigment")}
                  length={formOptions["pigment"].length}
                  updateOptions={this.updateOptions("pigment")}
                />
              </div>
              <div className="settings-block">
                <h6>Conditions</h6>
                <TagInput
                  tags={this.extractLables("conditions")}
                  length={formOptions["conditions"].length}
                  updateOptions={this.updateOptions("conditions")}
                />
              </div>
              <div className="settings-block">
                <h6>Products</h6>
                <TagInput
                  tags={this.extractLables("products")}
                  length={formOptions["products"].length}
                  updateOptions={this.updateOptions("products")}
                  options={formOptions["products"]}
                  showColorPicker
                  setColorPicker={this.setColorPicker}
                  buttonColor={this.state.productColor}
                />
                {this.state.show ? (
                  <SketchPicker
                    color={this.state.productColor}
                    onChange={this.changeColor}
                  />
                ) : null}
                {/* <div style={{ zIndex: "40" }}>
                  
                </div> */}
              </div>
              <div className="settings-block">
                <h6>Face charting options</h6>
                <TagInput
                  tags={this.extractLables("faceChartingOptions")}
                  length={formOptions["faceChartingOptions"].length}
                  updateOptions={this.updateOptions("faceChartingOptions")}
                  options={formOptions["faceChartingOptions"]}
                />
              </div>
              <div className="settings-block">
                <h6>Skin care specialist</h6>
                <TagInput
                  tags={this.extractLables("skinCareSpecialists")}
                  length={formOptions["skinCareSpecialists"].length}
                  updateOptions={this.updateOptions("skinCareSpecialists")}
                />
              </div>
              <button
                className="save-button px-4 mt-4"
                onClick={(e) => {
                  const { formOptions } = this.props;
                  const optionsToSave = {
                    services: formOptions.treatment.map((obj) => obj.label),
                    pigmentDamageInitiatedFrom: formOptions.pigment.map(
                      (obj) => obj.label
                    ),
                    conditions: formOptions.conditions.map((obj) => obj.label),
                    products: formOptions.products.map((obj) => {
                      return { label: obj.label, color: obj.color };
                    }),
                    // ***** Need to update face charting options here
                    faceChartingOptions: formOptions.faceChartingOptions.map(
                      (obj) => {
                        return { label: obj.label, color: obj.color };
                      }
                    ),
                    // faceChartingOptions: [],
                    skinCareSpecialists: formOptions.skinCareSpecialists.map(
                      (obj) => obj.label
                    ),
                  };
                  updateFormOptions(optionsToSave);
                  // ***** Should It be awaited
                }}
              >
                Save Form
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
);

SettingsForm.propTypes = {
  formOptions: PropTypes.object,
};

SettingsForm.defaultProps = {
  formOptions: {},
};

export default SettingsForm;
