import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomerModal from "../ReusableComponents/CustomerModal";
import { DEFAULT_IMAGE } from "../../constants";
import * as moment from "moment";
import { connect } from "react-redux";
import { getHumanizeTime } from "../../Utils/misc.utils";


const mapStateToProps = state => {
  return { 
    username: state?.User?.data?.username
  };
};

const ProfileInfo = connect(mapStateToProps) (
  class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false
    };
  }

  setModalShow = val => this.setState({ modalShow: val });

  handleClose = () => this.setModalShow(false);

  render() {
    const {
      canEdit,
      customerName,
      customerDOB,
      customerEmail,
      customerPhone,
      customerAddress,
      customerImageUrl,
      customerAllergy,
      customerId
    } = this.props;
    const { modalShow } = this.state;
    const customer = {
      name: customerName,
      dob:
        customerDOB && moment(customerDOB).isValid()
          ? moment(customerDOB).toDate()
          : null,
      email: customerEmail,
      phone: customerPhone,
      address: customerAddress,
      imageUrl: customerImageUrl,
      allergy: customerAllergy,
      id: customerId
    };
    return (
      <div className="profile-top d-flex flex-column flex-md-row justify-contnent-md-between align-items-md-center pl-lg-3">
        <div className="user-profile user-profile-lg d-flex align-items-center position-relative">
          <div className="user-img-canvas">
            <div className="user-img">
              <img
                src={customerImageUrl ? customerImageUrl : DEFAULT_IMAGE}
                alt="User Img"
              />
            </div>
            {customerAllergy && (
              <div className="exclamation-mark bg-primary">
                <i className="icon-warning"></i>
              </div>
            )}
          </div>
          <div className="user-detail">
            {this.props.username != "shannonboddie" ? (
              <h2 className="app-heading">{<div>{customerName ? customerName.slice(0, 3).padEnd(10, '*') : ""}</div>}</h2>
            ) : (
              <h2 className="app-heading">{<div>{customerName ? customerName : ""}</div>}</h2>
            )}
            {customerAllergy && (
              <h6 className="profile-info-allergy">{customerAllergy}</h6>
            )}
          </div>
        </div>
        <div className="profile-top-col">
          <ul className="profile-list">
            <li>
              <i className="icon-calendar text-primary"></i>
              {this.props.username != "shannonboddie" ? (getHumanizeTime("1/1/1800")) : (customerDOB && moment(customerDOB).isValid() ? moment(customerDOB).format("MM/DD/YYYY") : "N/A")}
            </li>
            <li>
              <i className="icon-mail text-primary"></i>
              {this.props.username != "shannonboddie" ? (<div>{customerEmail ? customerEmail.slice(0, 3).padEnd(10, '*') : "N/A"}</div>) : (<div>{customerEmail || "N/A"}</div>)}
            </li>
            <li>
              <i className="icon-phone text-primary"></i>
              {this.props.username != "shannonboddie" ? (<div>{customerPhone ? customerPhone.slice(0, 5).padEnd(10, '*') : "N/A"}</div>) : (<div>{customerPhone || "N/A"}</div>)}
            </li>
            <li>
              <i className="icon-map-marker text-primary"></i>
              {this.props.username != "shannonboddie" ? ("1600 Pennsylvania Avenue NW, Washington, DC 20500") : (customerAddress || "N/A")}
            </li>
          </ul>
        </div>
        {canEdit && (
          <a
            className="digit-box bg-primary"
            onClick={() => {
              this.setModalShow(true);
            }}
          >
            <i className="icon-edit"></i>
          </a>
        )}

        {modalShow && (
          <CustomerModal
            modalShow={modalShow}
            handleClose={this.handleClose}
            customer={customer}
            dispatch={this.props.dispatch}
          />
        )}
      </div>
    );
  }
})

export default ProfileInfo;

ProfileInfo.propTypes = {
  customerName: PropTypes.string,
  customerDOB: PropTypes.string,
  customerEmail: PropTypes.string,
  customerPhone: PropTypes.string,
  customerAddress: PropTypes.string,
  customerImageUrl: PropTypes.string,
  customerAllergy: PropTypes.string,
  customerId: PropTypes.string,
  dispatch: PropTypes.func,
  canEdit: PropTypes.bool
};

ProfileInfo.defaultProps = {
  customerName: "",
  customerDOB: "",
  customerEmail: "",
  customerPhone: "",
  customerAddress: "",
  customerImageUrl: "",
  customerAllergy: "",
  customerId: "",
  dispatch: () => {},
  canEdit: false
};
