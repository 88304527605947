import React from 'react';
import SmallLoader from './small.loader';

const Loader = () => {
  return (
    <div className="lds-canvas">
      <SmallLoader />
    </div>
  );
};

export default Loader;
