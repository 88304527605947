import { createAction } from "redux-actions";
import api from "../../Utils/api.utils";
import { uploadImageOnS3 } from "./customer";
import env from "../../Utils/env.utils";
import { errorExtractor, showNotification } from "../../Utils/misc.utils";

export const loadUserInfo = dispatch => async () => {
  try {
    dispatch(createAction("ME_LOADING")({}));
    const response = await api.get("/users/me");
    if (response?.data) {
      dispatch(
        createAction("ME_SUCCESSFUL")({
          ...response.data,
          avatar: response.data?.avatar
            ? env.mediaBaseUrl + response.data?.avatar
            : ""
        })
      );
    }
  } catch (err) {
    console.error(err);
    dispatch(createAction("ME_FAILURE")(err));
  }
};

export const updateUserInfo = dispatch => async ({ name, avatar }) => {
  let response = "";
  let url = "";
  if (avatar) {
    if (!avatar.includes(env.mediaBaseUrl)) {
      response = await uploadImageOnS3([avatar]);
      if (response?.data?.media?.length) {
        url = url = response?.data?.media[0]?.url;
      }
    } else {
      url = avatar.replace(env.mediaBaseUrl, "");
    }
  }

  const body = {
    fullName: name,
    avatar: url
  };
  try {
    response = await api.patch("/users/me", body);
    if (response?.data) {
      dispatch(
        createAction("ME_SUCCESSFUL")({
          ...response.data,
          avatar: response?.data?.avatar
            ? env.mediaBaseUrl + response.data?.avatar
            : ""
        })
      );
    }
  } catch (err) {
    console.error(err);
    showNotification("error", "Error", errorExtractor(err));
    return err;
  }
};
