import store from "../store";
import { createAction } from "redux-actions";
import api from "../../Utils/api.utils";
import { uploadImageOnS3 } from "./customer";
import * as moment from "moment";
import {
  compileImageUrl,
  errorExtractor,
  cleanObject
} from "../../Utils/misc.utils";
import env from "../../Utils/env.utils";

export const loadUserProfileInfo = dispatch => async id => {
  // ***** Will load user profile info from backend *****
  dispatch(createAction("LOADING_PROFILE_INFO")({}));
  let response;
  try {
    response = await api.get(`/customers/${id}`);
  } catch (err) {
    dispatch(createAction("LOADING_PROFILE_INFO_UNSUCCESSFUL")({ err }));
    return;
  }
  if (response.data) {
    const customer = response.data;
    const info = {
      // dob: moment(customer.dob).format("DD/MM/YYYY"),
      dob: customer.dob,
      name: customer.fullName,
      email: customer.email || null,
      phone: customer.phone,
      address: customer.address,
      allergy: customer.allergies,
      imageUrl: customer.avatar ? compileImageUrl(customer.avatar) : "",
      id: customer._id ? customer._id : id
    };
    dispatch(
      createAction("LOADING_PROFILE_INFO_SUCCESSFUL")({ profileInfo: info })
    );
  }
};

export const updateProfileInfo = async (dispatch, addCustomer) => {
  const { imageUrl } = addCustomer;
  let response = "";
  if (imageUrl && !imageUrl.includes(env.mediaBaseUrl)) {
    response = await uploadImageOnS3([imageUrl]);
  }

  let url = "";
  if (
    response !== "" &&
    response !== undefined &&
    response.data !== undefined &&
    "media" in response.data &&
    response.data.media.length
  ) {
    url = response.data.media[0].url;
  }
  const body = {
    dob:
      addCustomer.dob && moment(addCustomer.dob).isValid()
        ? moment(addCustomer.dob).format("YYYY-MM-DD")
        : null,
    fullName: addCustomer.name,
    email: addCustomer.email || null,
    phone: addCustomer.phone,
    address: addCustomer.address
  };
  if (url) {
    body.avatar = url;
  } else {
    body.avatar = imageUrl.includes(env.mediaBaseUrl)
      ? imageUrl.replace(env.mediaBaseUrl, "")
      : "";
  }
  if (addCustomer.allergy !== "") {
    // ***** Bug needs to be fixed on backend
    body.allergies = addCustomer.allergy;
  }
  try {
    const res = await api.put(`/customers/${addCustomer.id}`, body);
    const _id = res.data._id;
    url = res?.data?.avatar;
    dispatch(
      createAction("UPDATE_PROFILE_INFO")({
        profileInfo: {
          ...addCustomer,
          id: _id,
          imageUrl: url ? compileImageUrl(url) : "",
          dob: addCustomer.dob
        }
      })
    );
  } catch (err) {
    console.log(err);
    return errorExtractor(err);
  }
};

export const clearUserProfileInfo = dispatch =>
  dispatch(createAction("CLEAR_PROFILE_INFO")({}));
