import * as moment from "moment";
import { cloneDeep } from "lodash";

const initialState = {
  loading: false,
  err: null,
  formDetails: {
    date: null,
    treatmentPerformed: [],
    priorCosmeticProcedures: "",
    fitzpatrickType: "",
    glogauClassification: "",
    skinType: "",
    conditions: [],
    faceCharting: "",
    SubDermisThickness: "Thin",
    PatchTestPerformed: false,
    FineLinesAndWrinkles: {
      wrinkleAssessment: "",
      elastosisAssessment: ""
    },
    Pigmentation: {
      PhotoDamage: "",
      PigmentDamageInitiatedFrom: ""
    },
    Acne: {
      grade: "",
      lesionsCount: {
        leftSide: "",
        rightSide: ""
      },
      LesionsIdentified: {
        openComedones: "",
        openPapules: "",
        closedComedones: "",
        closedPapules: "",
        nodules: "",
        cysts: "",
        milia: ""
      }
    },
    Sensitivity: {
      rosaceaStage: [],
      skinType: "",
      location: [],
      hyperSensitive: false,
      skinVisiblePeeling: false,
      openWounds: false
    },
    notes: "",
    samplesGiven: "",
    productsPurchased: [],
    ProgressNotes: "",
    assessment: [],
    plan: [],
    isVerbalConsent: false,
    isWrittenConsent: false,
    isVerbalInstructions: false,
    isWrittenInstructions: false,
    signatureAndDate: {
      signature: "",
      date: ""
    }
  }
};

const PDAFormData = (state = initialState, action) => {
  if (action.type === "LOADING_PDA_FORM_DATA") {
    return { loading: true, err: null, formDetails: { ...state.formDetails } };
  }
  if (action.type === "LOADING_PDA_FORM_DATA_SUCCESSFUL") {
    const { payload } = action;
    return {
      loading: false,
      err: null,
      formDetails: { ...payload.formDetails }
    };
  }
  if (action.type === "LOADING_PDA_FORM_DATA_UNSUCCESSFUL") {
    const { payload } = action;
    return {
      loading: false,
      err: payload.err,
      formDetails: { ...cloneDeep(initialState.formDetails) }
    };
  }
  if (action.type === "LOADED_PDA_FORM_WITH_NO_DATA") {
    return {
      loading: false,
      err: null,
      formDetails: {
        ...cloneDeep(initialState.formDetails),
        date: moment().toDate()
      }
    };
  }
  if (action.type === "CLEAR_PDA_FORM_DATA") {
    return cloneDeep(initialState);
  }
  return state;
};

export default PDAFormData;
