import { createAction } from "redux-actions";
import { showNotification, errorExtractor } from "../../Utils/misc.utils";
import api from '../../Utils/api.utils';

export const loadFormOptions = async (dispatch) => {
  dispatch(createAction("LOADING_FORM_OPTIONS")({}));
  let link = "/setting";
  let response;
  try {
    response = await api.get(link);
  } catch (err) {
    dispatch(createAction("LOADING_FORM_OPTIONS_UNSUCCESSFUL")({ err }));
    return;
  }
  if (Object.keys(response.data).length) {
    const opts = response.data;
    const options = {
      treatment: opts.services.map((val, index) => {
        return { value: val, label: val };
      }),
      pigment: opts.pigmentDamageInitiatedFrom.map((val, index) => {
        return { value: val, label: val };
      }),
      conditions: opts.conditions.map((val, index) => {
        return { value: val, label: val };
      }),
      products: opts.products.map((val, index) => {
        return { value: val.label, label: val.label, color: val.color };
      }),
      skinCareSpecialists: opts.skinCareSpecialists.map((val, index) => {
        return { value: val, label: val };
      }),
      faceChartingOptions: opts.faceChartingOptions.map((val, index) => {
        return { value: val.label, label: val.label, color: val.color };
      }),
    };
    // ***** Need to run once it is backend is updated
    dispatch(createAction("LOADING_FORM_OPTIONS_SUCCESSFUL")({ options }));
  }
};

export const updateFormOptions = async (formData) => {
  let link = "/setting";
  try {
    let response = await api.put(link, formData);
    showNotification(
      "success",
      "Update Form Options Success",
      "Form Options Updated Successfully"
    );
  } catch (err) {
    console.log(err);
    showNotification("error", "Update Form Error", errorExtractor(err));
  }
};
