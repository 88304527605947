import { useState } from "react";
import api from "../../Utils/api.utils";

export const useSendEmail = () => {
  const [res, setRes] = useState({
    data: null,
    loading: false,
    error: null,
    hasData: false
  });

  const sendEmailRequest = async ({ formId, customerName, formName, toEmail }) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const response = await api.post(`email/emailForm/${formId}`, { customerName, formName, toEmail });
      setRes({
        data: response?.data,
        loading: false,
        error: null,
        hasData: true
      });
    } catch (error) {
      console.error(error);
      setRes({
        data: null,
        loading: false,
        error,
        hasData: true
      });
    }
  };

  return { res, sendEmailRequest };
};
