import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useSendEmail } from "../../Redux/actions/sendEmail";
import SmallLoader from "../../Components/Common/small.loader";
import { errorExtractor, showNotification } from "../../Utils/misc.utils";
import { EmailSchema, EMAIL_FORM } from "../../Containers/EmailForm";
import { useSelector } from "react-redux";

const EmailModal = props => {
  const { res, sendEmailRequest } = useSendEmail();
  const { showEmailModal, handleClose, emailData } = props;
  const { register, errors, handleSubmit } = useForm({
    validationSchema: EmailSchema
  });
  const customer = useSelector(state => state.ProfileInfo.profileInfo);
  const [email, setEmail] = useState(customer?.email || "");

  useEffect(() => {
    setEmail(customer?.email || "");
  }, [customer]);

  useEffect(() => {
    if (!res.loading && res.data) {
      handleClose();
      showNotification("success", "Success", "Email successfully sent");
    } else if (!res.loading && res.error) {
      showNotification("error", "Error", errorExtractor(res.error));
    }
  }, [res]);

  const sendEmail = async data => {
    if (!res.loading) {
      sendEmailRequest({
        formId: emailData.formId,
        toEmail: data?.email
      });
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="app-modal"
      scrollable
      show={showEmailModal}
      onHide={handleClose}
      backdrop="static"
    >
      <Modal.Body>
        <Form onSubmit={handleSubmit(sendEmail)} className="w-100">
          <div className="customer-profile customer-incomplete">
            <div className="modal-canvas">
              <div className="cp-inner d-flex flex-column flex-md-row justify-contnent-md-between align-items-md-center mb-0">
                <div className="user-profile user-profile-lg d-flex align-items-center w-100">
                  <Form.Group className="modal-group modalGroup-name mb-0">
                    <Form.Control
                      type="text"
                      ref={register}
                      name={EMAIL_FORM.EMAIL}
                      placeholder="youremail@example.com"
                      isInvalid={errors[EMAIL_FORM.EMAIL]}
                      defaultValue={email}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="btn addCustomer-btn addCustomer-btn-complete"
                type="submit"
                disabled={res.loading}
              >
                {res.loading ? "Sending" : "Send email"}
                {res.loading && <SmallLoader />}
              </Button>
            </div>
            <Button
              onClick={handleClose}
              className="btn close-btn close-circle"
            >
              <i className="icon-cross"></i>
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EmailModal;
