import { cloneDeep } from "lodash";

const initialState = {
  visited: []
};

const RouteStack = (state = initialState, action) => {
  if (action.type === "PUSH_ROUTE") {
    const stateCopy = cloneDeep(state.visited);
    return { visited: [...stateCopy, action.payload] };
  }
  if (action.type === "POP_ROUTE") {
    const stateCopy = cloneDeep(state.visited);
    stateCopy.pop();
    return { visited: stateCopy };
  }
  if (action.type === "POPULATE_SESSION_WITH_ROUTES") {
    sessionStorage.setItem("routes", JSON.stringify(state.visited));
  }
  if (action.type === "RELOAD_ROUTES_FROM_SESSION_STORAGE") {
    const routes = sessionStorage.getItem("routes");
    if (routes) {
      const newState = {
        visited: JSON.parse(sessionStorage.getItem("routes"))
      };
      sessionStorage.removeItem("routes");
      return newState;
    }
    sessionStorage.removeItem("routes");
  }
  return state;
};

export default RouteStack;
