import React from "react";
import TagsInput from "react-tagsinput";
import { Button } from "react-bootstrap";

import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.

import PropTypes from "prop-types";
import { TagStyleApply } from "../ReusableComponents/styles";

export default class TagInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tags: this.props.tags, tag: "" };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        tags: JSON.parse(JSON.stringify(this.props.tags))
      });
    }
  }

  handleChange = tags => {
    const filteredTags = [...new Set(tags)];
    if (filteredTags.length === this.props.length) {
      return;
    } else if (filteredTags.length >= this.props.length) {
      const tagToAdd = filteredTags.filter(x => !this.state.tags.includes(x));
      this.props.updateOptions(tagToAdd, "add");
    } else {
      const tagToRemove = this.state.tags.filter(
        x => !filteredTags.includes(x)
      );
      this.props.updateOptions(tagToRemove, "remove");
    }
  };

  handleChangeInput = tag => {
    this.setState({ tag });
  };

  defaultRenderTag = props => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    let color = "#f2594f";
    const displayTag = getTagDisplayValue(tag);
    if (this.props.options.length) {
      this.props.options.map(option => {
        if (displayTag === option.label) {
          color = option.color;
        }
      });
    }
    return (
      <span
        key={key}
        style={{ backgroundColor: color, border: color }}
        {...other}
      >
        {displayTag}
        {!disabled && (
          <a className={classNameRemove} onClick={e => onRemove(key)} />
        )}
      </span>
    );
  };

  defaultRenderInput = props => {
    let { onChange, value, addTag, ...other } = props;
    return (
      <div>
        <input type="text" onChange={onChange} value={value} {...other} />
        {this.props.showColorPicker ? (
          <Button
            style={{
              background: this.props.buttonColor,
              border: this.props.buttonColor
            }}
            onClick={this.props.setColorPicker}
          />
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <TagStyleApply>
        <TagsInput
          value={this.state.tags}
          onChange={this.handleChange}
          inputValue={this.state.tag}
          onChangeInput={this.handleChangeInput}
          inputProps={{
            placeholder: "Add Option"
          }}
          renderTag={this.defaultRenderTag}
          renderInput={this.defaultRenderInput}
        />
      </TagStyleApply>
    );
  }
}

TagInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  length: PropTypes.number,
  updateOptions: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  showColorPicker: PropTypes.bool,
  setColorPicker: PropTypes.func,
  buttonColor: PropTypes.string
};

TagInput.defaultProps = {
  tags: [],
  updateOptions: () => {},
  length: 0,
  options: [],
  showColorPicker: false,
  setColorPicker: () => {},
  buttonColor: "#f2594f"
};
