import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import {
  EMAIL_REGEX,
  DATE_REGEX,
  PHONE_REGEX,
  IMAGE_SIZE_LIMIT,
  ALLOWED_IMAGE_TYPES
} from "../../constants";
import { updateProfileInfo } from "../../Redux/actions/profileInfo";
import { checkDateFormat, showNotification } from "../../Utils/misc.utils";
import DateSelector from "../ReusableComponents/Datepicker";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SmallLoader from "../Common/small.loader";
import moment from "moment";
import InputMask from "react-input-mask";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return { 
    username: state?.User?.data?.username
  };
};

const CustomerModal = connect(mapStateToProps) (
  class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveCustomerLoading: false,
      addCustomer: this.props.customer,
      validInput: {
        name: true,
        dob: true,
        email: true,
        phone: true,
        address: true,
        imageUrl: true,
        allergy: true
      },
      requiredInput: {
        name: true,
        dob: false,
        email: false,
        phone: false,
        address: false,
        imageUrl: false,
        allergy: false
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.customer, this.state.addCustomer) &&
      !isEqual(prevProps.customer, this.props.customer)
    ) {
      this.setState({ addCustomer: { ...this.props.customer } });
    }
  }

  // setCustomerImage = () => {};
  setCustomerImage = async e => {
    if (e.target.files) {
      const file = e?.target?.files[0];
      if (file?.size < IMAGE_SIZE_LIMIT) {
        if (ALLOWED_IMAGE_TYPES.includes(file?.type)) {
          this.setState({
            addCustomer: {
              ...this.state.addCustomer,
              imageUrl: URL.createObjectURL(file)
            }
          });
        } else {
          alert("Allowed image types are jpg, png and svg");
        }
      } else {
        alert(`Image Size Limit Exceeded (10 MB)`);
      }
    }
  };
  // setCustomerField = () => {};
  setCustomerField = (field, val) =>
    this.setState(prevState => ({
      addCustomer: { ...prevState.addCustomer, [field]: val }
    }));

  checkValidFormInputs = () => {
    const { validInput, addCustomer, requiredInput } = this.state;
    const modify = {};
    let invalidInput = false;
    Object.keys(addCustomer).map(key => {
      if (requiredInput[key] && (!validInput[key] || !addCustomer[key])) {
        modify[key] = false;
        invalidInput = true;
      }
      if (
        key === "email" &&
        addCustomer[key] &&
        !EMAIL_REGEX.test(addCustomer[key]?.toLowerCase())
      ) {
        modify[key] = false;
        invalidInput = true;
      }
      if (
        key === "phone" &&
        addCustomer[key] &&
        !PHONE_REGEX.test(addCustomer[key]?.toLowerCase())
      ) {
        modify[key] = false;
        invalidInput = true;
      }
      if (
        key === "dob" &&
        addCustomer[key] &&
        (!DATE_REGEX.test(moment(addCustomer[key]).format("DD/MM/YYYY")) ||
          checkDateFormat(moment(addCustomer[key]).format("DD/MM/YYYY")))
      ) {
        modify[key] = false;
        invalidInput = true;
      }
    });
    this.setState({ validInput: { ...validInput, ...modify } });
    return invalidInput;
  };

  updateValidInput = (key, val) => {
    this.setState(prevState => ({
      validInput: { ...prevState.validInput, [key]: val }
    }));
  };

  checkValidInput = (key, val) => {
    if (key === "email") {
      this.updateValidInput(
        "email",
        !(val && !EMAIL_REGEX.test(val?.toLowerCase()))
      );
    } else if (key === "phone") {
      this.updateValidInput("phone", !(val && !PHONE_REGEX.test(val)));
    } else if (key === "dob") {
      this.updateValidInput(
        "dob",
        !(
          val &&
          (!DATE_REGEX.test(moment(val).format("DD/MM/YYYY")) ||
            checkDateFormat(moment(val).format("DD/MM/YYYY")))
        )
      );
    }
  };

  updateCustomer = async () => {
    // ***** This will be updated once db is setup *****
    const { addCustomer } = this.state;
    if (this.checkValidFormInputs()) {
      return;
    }
    if (this.state.saveCustomerLoading) {
      showNotification(
        "info",
        "Update Customer In Progress",
        "Please wait until the call is finished"
      );
      return;
    }
    this.setState({ saveCustomerLoading: true });
    const err = await updateProfileInfo(this.props.dispatch, addCustomer);
    this.setState({ saveCustomerLoading: false });
    if (err) {
      showNotification("error", "Add Customer Error", err);
    } else {
      showNotification(
        "success",
        "Update Customer Success",
        "Customer updated successfully"
      );
      this.setState({
        addCustomer: {
          name: "",
          dob: null,
          email: null,
          phone: "",
          address: "",
          imageUrl: "",
          allergy: ""
        }
      });
      this.props.handleClose();
    }
  };

  invokeClose = () => {
    if (!isEqual(this.state.addCustomer, this.props.customer)) {
      const response = window.confirm(
        "You have unsaved changes that will be lost."
      );
      if (response) {
        this.props.handleClose();
      }
    } else {
      this.props.handleClose();
    }
  };

  canUpdateCustomer = () => {
    const { validInput, requiredInput, addCustomer } = this.state;
    let disabled = false;
    if (this.props.username != "shannonboddie") {
      disabled = true;
      return disabled;
    }
    Object.keys(addCustomer).forEach(key => {
      if (requiredInput[key] && (!validInput[key] || !addCustomer[key])) {
        disabled = true;
      }
    });
    return disabled;
  };

  render() {
    const { addCustomer, validInput, saveCustomerLoading } = this.state;
    const { modalShow, handleClose } = this.props;
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-modal"
        scrollable
        show={modalShow}
        onHide={this.invokeClose}
        backdrop="static"
      >
        <Modal.Body>
          <Form className="w-100">
            <div className="customer-profile customer-incomplete">
              <div className="modal-canvas">
                <div className="cp-inner d-flex flex-column flex-md-row justify-contnent-md-between align-items-md-center mb-0">
                  <div className="user-profile user-profile-lg d-flex align-items-center">
                    <div className="user-img-canvas">
                      <label className="customer-img-upload bg-primary">
                        {addCustomer.imageUrl ? (
                          <img src={addCustomer.imageUrl} alt="User Img" />
                        ) : (
                          <React.Fragment>
                            <input
                              type="file"
                              onChange={this.setCustomerImage}
                              accept="image/*"
                            />
                            <i className="icon-edit"></i>
                          </React.Fragment>
                        )}
                        {/* *****  file upload to be added here once db implemented***** */}
                      </label>
                      {addCustomer.imageUrl && (
                        <span
                          className="user-cross"
                          onClick={() =>
                            this.setState({
                              addCustomer: {
                                ...this.state.addCustomer,
                                imageUrl: ""
                              }
                            })
                          }
                        >
                          <i className="icon-cross"></i>
                        </span>
                      )}
                    </div>
                    <div className="user-detail w-100">
                      <Form.Group className="modal-group modalGroup-name mb-0">
                        <Form.Control
                          value={this.props.username != "shannonboddie" ?  addCustomer.name.slice(0, 3).padEnd(10, '*') || "" : addCustomer.name || ""}
                          onChange={e => {
                            this.setCustomerField("name", e.target.value);
                            this.checkValidInput("name", e.target.value);
                          }}
                          type="text"
                          placeholder="Jane Doe"
                          isInvalid={!validInput.name}
                          onBlur={e =>
                            this.checkValidInput("name", e.target.value)
                          }
                        />
                      </Form.Group>
                      <div className="d-flex align-items-center">
                        <div className="exclamation-mark">
                          <i className="icon-warning"></i>
                        </div>
                        <Form.Group className="modal-group mb-0">
                          <Form.Control
                            type="text"
                            placeholder="Special treatment needs"
                            value={addCustomer.allergy}
                            onChange={e => {
                              this.setCustomerField("allergy", e.target.value);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className="profile-top-col">
                    <ul className="profile-list">
                      <li>
                        <i className="icon-calendar text-primary"></i>
                        <DateSelector
                          value={addCustomer.dob}
                          handleChange={date => {
                            this.setCustomerField("dob", date);
                            this.checkValidInput("dob", date);
                          }}
                          onBlur={field =>
                            this.checkValidInput(field, addCustomer.dob)
                          }
                        />
                      </li>
                      <li>
                        <i className="icon-mail text-primary"></i>
                        <Form.Group className="modal-group modalGroup-list mb-0">
                          <Form.Control
                            value={this.props.username != "shannonboddie" ? addCustomer.email.slice(0, 3).padEnd(10, '*') || "" : addCustomer.email || ""}
                            onChange={e => {
                              this.setCustomerField("email", e.target.value);
                              this.checkValidInput("email", e.target.value);
                            }}
                            type="text"
                            placeholder="Email"
                            isInvalid={!validInput.email}
                            onBlur={e =>
                              this.checkValidInput("email", e.target.value)
                            }
                          />
                        </Form.Group>
                      </li>
                      <li>
                        <i className="icon-phone text-primary"></i>
                        <Form.Group className="modal-group modalGroup-list mb-0">
                          <InputMask
                            value={this.props.username != "shannonboddie" ? addCustomer.phone.slice(0, 5).padEnd(10, '*') || "" : addCustomer.phone || ""}
                            onChange={e => {
                              this.setCustomerField("phone", e.target.value);
                              this.checkValidInput("phone", e.target.value);
                            }}
                            type="text"
                            placeholder="Phone"
                            onBlur={e =>
                              this.checkValidInput("phone", e.target.value)
                            }
                            mask="(999) 999-9999"
                            maskChar="_"
                            className={
                              !validInput.phone
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                        </Form.Group>
                      </li>
                      <li>
                        <i className="icon-map-marker text-primary"></i>
                        <Form.Group className="modal-group modalGroup-list mb-0">
                          <Form.Control
                            value={addCustomer.address}
                            onChange={e => {
                              this.setCustomerField("address", e.target.value);
                            }}
                            type="text"
                            placeholder="Address"
                            isInvalid={!validInput.address}
                            onBlur={() => this.checkValidInput("address")}
                          />
                        </Form.Group>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={this.updateCustomer}
                  className="btn addCustomer-btn"
                  disabled={this.canUpdateCustomer() || saveCustomerLoading}
                >
                  {saveCustomerLoading ? "Updating" : "Update Customer"}
                  {saveCustomerLoading && <SmallLoader />}
                </Button>
              </div>
              <Button
                onClick={this.invokeClose}
                className="btn close-btn close-circle"
              >
                <i className="icon-cross"></i>
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
})

export default CustomerModal;

CustomerModal.propTypes = {
  customer: PropTypes.object,
  modalShow: PropTypes.bool,
  handleClose: PropTypes.func,
  dispatch: PropTypes.func
};

CustomerModal.defaultProps = {
  customer: {
    name: "",
    dob: "",
    email: "",
    phone: "",
    address: "",
    imageUrl: "",
    allergy: "",
    id: ""
  },
  modalShow: false,
  handleClose: () => {},
  dispatch: () => {}
};
