import React, { Component } from "react";
import PropTypes from "prop-types";
import CanvasDraw from "react-canvas-draw";
import Form from "react-bootstrap/Form";
import Sidebar from "../../Components/Sidebar";
import NavigationButtons from "../../Components/NavigationButtons";
import ProfileInfo from "../../Components/ProfileInfo";
import CreatableMulti from "../../Components/SelectComponents/Creatable";
import { withRouter } from "react-router-dom";
import {
  loadUserProfileInfo,
} from "../../Redux/actions/profileInfo";
import {
  loadPDAFormData,
  addPDAFormData,
} from "../../Redux/actions/pdaFormData";
import { loadFormOptions } from "../../Redux/actions/formOptions";
import { connect } from "react-redux";
import { createAction } from "redux-actions";
import { cloneDeep } from "lodash";
import { showNotification } from "../../Utils/misc.utils";
import DateSelector from "../../Components/ReusableComponents/Datepicker";
import SmallLoader from "../../Components/Common/small.loader";
import EmailModal from "../../Components/EmailModal";

const fillText = "Vestibulum a laoreet nulla. Phasellus ornare efficitur dapibus. Phasellus a felis eu metus vestibulum cursus et non nisi. Sed eget mauris non neque euismod fermentum eu et lacus. Maecenas et sem ornare, laoreet purus vel, consequat elit. Quisque luctus, augue ac scelerisque condimentum, urna turpis rhoncus nisi, ac fermentum massa leo eu mauris. Etiam aliquam sapien scelerisque, porttitor nisl vitae, vulputate leo. Nullam vulputate at mauris sit amet molestie." 

const mapStateToProps = (state) => {
  return {
    customer: { ...state.ProfileInfo.profileInfo },
    formDetails: state.PDAFormData.formDetails,
    formOptions: state.FormOptions.options,
    username: state?.User?.data?.username,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserProfileInfo: loadUserProfileInfo(dispatch),
    loadPDAFormData: loadPDAFormData(dispatch),
    addPDAFormData: addPDAFormData(dispatch),
    dispatch: dispatch,
  };
};

const PDATreatmentForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class PDAForm extends Component {
    constructor(props) {
      super(props);
      const { formDetails } = props;
      this.state = {
        saveFormLoading: false,
        printLoading: false,
        formData: cloneDeep(formDetails),
        brushColor: 0,
        validDate: true,
        brushSize: "0",
        dirty: false,
        showEmailModal: false,
      };
    }

    canvasRef = "";
    setRef = (canvas) => {
      this.canvasRef = canvas;
    };

    componentDidMount() {
      this.props.dispatch(
        createAction("RELOAD_ROUTES_FROM_SESSION_STORAGE")({})
      );
      loadFormOptions(this.props.dispatch).then((obj) => {
        this.props.loadPDAFormData(
          this.props.match.params.formId,
          this.props?.history?.push
        );
      });
      this.props.loadUserProfileInfo(this.props.match.params.customerId);
      // this.props.loadPDAFormData ***** Will be added once backend is setup *****
      // this.props.loadUserFormOptions ***** Will be added once backend is setup *****
    }

    componentDidUpdate(prevProps) {
      if (prevProps != this.props) {
        let loadedData = cloneDeep(this.props.formDetails);
        const addColor = ["productsPurchased", "plan"];
        addColor.map((field) => {
          loadedData[field].map((elem, index) => {
            const { products } = this.props.formOptions;
            products.map((product) => {
              if (product.label === elem.label && product.color !== undefined) {
                loadedData[field][index].color = product.color;
              }
            });
          });
        });
        this.setState({
          formData: loadedData,
        });
      }
    }

    checkValidInput = () => {
      const { formData } = this.state;
      let valid = false;
      if (!this.state.validDate) {
        return true;
      }
      if (formData?.date === "" || formData?.date === null) {
        this.setState({
          validDate: false,
        });
        valid = true;
      }
      return valid;
    };

    componentWillUnmount() {
      this.props.dispatch(createAction("CLEAR_PDA_FORM_DATA")({}));
    }

    updateFormData = (arr) => (val) => {
      let objectToModify;
      let property;
      let { formData } = this.state;
      formData = cloneDeep(formData);
      arr.forEach((elem, index) => {
        if (objectToModify) {
          objectToModify = objectToModify[property];
          property = elem;
        } else {
          objectToModify = formData;
          property = elem;
        }
      });
      objectToModify[property] = val;
      this.setState({
        formData: formData,
        dirty: true,
      });
      if (arr.includes("date")) {
        this.setState({
          validDate: true,
        });
      }
    };

    availableColors = {
      cFF0000: 1,
      c800000: 2,
      cFFFF00: 3,
      c808000: 4,
      c00FF00: 5,
      c008000: 6,
      c00FFFF: 7,
      c008080: 8,
      c0000FF: 9,
      cFF00FF: 10,
      c800080: 11,
    };

    containerRef = React.createRef();

    renderCanvasOptions = () => {
      const { faceChartingOptions } = this.props.formOptions;

      return faceChartingOptions.map((option, index) => {
        const colorIndex = this.availableColors[option.color.replace("#", "c")];
        return (
          <div
            key={index}
            className={`options-box options-box-${colorIndex}`}
            onClick={() => this.setState({ brushColor: index })}
          >
            <span className="te-option-digit">Text</span>
            <span className="te-option-text">{option.label}</span>
          </div>
        );
      });
    };

    brushSizesAvailable = {
      0: { size: 6, label: "Small" },
      1: { size: 9, label: "Medium" },
      2: { size: 12, label: "Large" },
    };

    renderBrushOptions = () => {
      const { brushSize } = this.state;
      return Object.keys(this.brushSizesAvailable).map((key, index) => {
        const active = key === brushSize ? "active" : "";
        return (
          <div
            key={index}
            className={`options-box options-box-size ${active}`}
            onClick={() => this.setState({ brushSize: key })}
          >
            <span className="te-option-digit">Text</span>
            <span className="te-option-text">
              {this.brushSizesAvailable[key].label}
            </span>
          </div>
        );
      });
    };

    printDocument = async () => {
      const wholeDocument = this?.containerRef?.current;
      const { dirty } = this.state;
      let isFormUpdated = true;
      if (dirty) {
        this.setState({ printLoading: true });
        isFormUpdated = await this.saveFormData(false, false);
        this.setState({ printLoading: false });
      }
      if (isFormUpdated && wholeDocument) {
        const oldPage = document.body.innerHTML;
        document.body.innerHTML = wholeDocument.innerHTML;
        window.print();
        document.body.innerHTML = oldPage;
        this.props.dispatch(createAction("POPULATE_SESSION_WITH_ROUTES")({}));
        window.location.reload(false);
      }
    };

    saveFormData = async (goToTreatments = true, enableLoading = true) => {
      if (this.checkValidInput()) {
        return;
      }
      const { formData } = this.state;
      if (enableLoading) {
        this.setState({ saveFormLoading: true });
      }
      const err = await this.props.addPDAFormData(
        this.props.match.params.formId,
        cloneDeep(this.state.formData)
      );
      if (enableLoading) {
        this.setState({ saveFormLoading: false });
      }
      if (err) {
        showNotification("error", "Update Form Error", err);
      } else {
        showNotification(
          "success",
          "Update Form Success",
          "Form Updated Successfully"
        );
        if (goToTreatments) {
          this.props.history.push(
            "/profile/" + this.props.match.params.customerId
          );
        } else {
          this.setState({ dirty: false });
          return true;
        }
      }
    };

    render() {
      const { formData, brushColor, validDate, saveFormLoading, printLoading } = this.state;
      let { showEmailModal } = this.state;
      const { customer, formOptions } = this.props;
      return (
        <div className="off-canvas-container">
          <Sidebar />
          <div className="main-content treatmentDetail-content">
            <NavigationButtons />
            <div className="pr-xl-5">
              <ProfileInfo
                customerName={customer.name}
                customerDOB={customer.dob}
                customerEmail={customer.email}
                customerPhone={customer.phone}
                customerAddress={customer.address}
                customerImageUrl={customer.imageUrl}
                customerAllergy={customer.allergy}
                customerId={customer.id}
              />
              <div
                className="treatmentDetail-main pt-3"
                ref={this.containerRef}
              >
                <h2 className="app-heading">Treatment Details</h2>
                <p className="app-paragraph">
                  Treatment detail form about the customer.
                </p>
                <Form>
                  <div className="treatmentDetail-row">
                    <div className="row row-space-ten">
                      <div className="col-12 col-sm-4 col-lg-3 col-xl-2">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            Treatment Date
                          </Form.Label>
                          <DateSelector
                            value={formData.date}
                            handleChange={(date) =>
                              this.updateFormData(["date"])(date)
                            }
                            onBlur={this.checkValidInput}
                            field="date"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12 col-sm-4 col-lg-4 col-xl-3">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            <div className="addPadding4px">
                              Treatment Performed
                            </div>
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.treatment}
                            selected={formData.treatmentPerformed}
                            updateParentState={this.updateFormData([
                              "treatmentPerformed",
                            ])}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12 col-sm-4 col-lg-5 col-xl-7">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            Prior Cosmetic Procedures
                          </Form.Label>
                          <Form.Control
                            value={formData.priorCosmeticProcedures}
                            onChange={(e) =>
                              this.updateFormData(["priorCosmeticProcedures"])(
                                e.target.value
                              )
                            }
                            type="text"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row row-space-ten pr-xl-5 row-max-width">
                      <div className="col-12 col-sm-6 col-lg-3 col-xl-2">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            Fitzpatrick Type
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.fitzpatrick}
                            selected={formData.fitzpatrickType}
                            isMulti={false}
                            updateParentState={this.updateFormData([
                              "fitzpatrickType",
                            ])}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-3 col-xl-3">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            Glogau Classification
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.glogauClassification}
                            selected={formData.glogauClassification}
                            updateParentState={this.updateFormData([
                              "glogauClassification",
                            ])}
                            isMulti={false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-3">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            Skin Type
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.skinType}
                            selected={formData.skinType}
                            updateParentState={this.updateFormData([
                              "skinType",
                            ])}
                            isMulti={false}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-3">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            Conditions
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.conditions}
                            selected={formData.conditions}
                            updateParentState={this.updateFormData([
                              "conditions",
                            ])}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row row-space-ten">
                      <div className="col-12 col-lg-5 col-xl-4">
                        <div className="treatmentDetail-block">
                          <h6 className="block-label">Sub-Dermis Thickness</h6>
                          <ul className="treatmentDetail-list">
                            <li>
                              <a
                                className={
                                  formData.SubDermisThickness === "Thin"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  this.updateFormData(["SubDermisThickness"])(
                                    "Thin"
                                  )
                                }
                              >
                                Thin
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  formData.SubDermisThickness === "Medium"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  this.updateFormData(["SubDermisThickness"])(
                                    "Medium"
                                  )
                                }
                              >
                                Medium
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  formData.SubDermisThickness === "Thick"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  this.updateFormData(["SubDermisThickness"])(
                                    "Thick"
                                  )
                                }
                              >
                                Thick
                              </a>
                            </li>
                          </ul>
                          <h6 className="block-label">Patch Test Performed</h6>
                          <ul className="treatmentDetail-list">
                            <li>
                              <a
                                className={
                                  formData.PatchTestPerformed ? "active" : ""
                                }
                                onClick={() =>
                                  this.updateFormData(["PatchTestPerformed"])(
                                    true
                                  )
                                }
                              >
                                Yes
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  formData.PatchTestPerformed ? "" : "active"
                                }
                                onClick={() =>
                                  this.updateFormData(["PatchTestPerformed"])(
                                    false
                                  )
                                }
                              >
                                No
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="treatmentDetail-block">
                          <h4 className="app-heading-medium">
                            Fine Lines & Wrinkles:
                          </h4>
                          <div className="w-100">
                            <Form.Group className="form-group-app">
                              <Form.Label className="block-label">
                                Wrinkle Assessment:
                              </Form.Label>
                              <CreatableMulti
                                options={formOptions.wrinkles}
                                isMulti={false}
                                selected={
                                  formData["FineLinesAndWrinkles"][
                                    "wrinkleAssessment"
                                  ]
                                }
                                updateParentState={this.updateFormData([
                                  "FineLinesAndWrinkles",
                                  "wrinkleAssessment",
                                ])}
                              />
                            </Form.Group>
                          </div>
                          <div className="w-100">
                            <Form.Group className="form-group-app">
                              <Form.Label className="block-label">
                                Elastosis Assessment:
                              </Form.Label>
                              <CreatableMulti
                                options={formOptions.elastosis}
                                isMulti={false}
                                selected={
                                  formData["FineLinesAndWrinkles"][
                                    "elastosisAssessment"
                                  ]
                                }
                                updateParentState={this.updateFormData([
                                  "FineLinesAndWrinkles",
                                  "elastosisAssessment",
                                ])}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="treatmentDetail-block">
                          <h4 className="app-heading-medium">Pigmentation</h4>
                          <div className="w-100">
                            <Form.Group className="form-group-app">
                              <Form.Label className="block-label">
                                Photo Damage
                              </Form.Label>
                              <CreatableMulti
                                options={formOptions.photo}
                                isMulti={false}
                                selected={
                                  formData["Pigmentation"]["PhotoDamage"]
                                }
                                updateParentState={this.updateFormData([
                                  "Pigmentation",
                                  "PhotoDamage",
                                ])}
                              />
                            </Form.Group>
                          </div>
                          <div className="w-100">
                            <Form.Group className="form-group-app">
                              <Form.Label className="block-label">
                                Pigment damage initiated from
                              </Form.Label>
                              <CreatableMulti
                                options={formOptions.pigment}
                                isMulti={false}
                                selected={
                                  formData["Pigmentation"][
                                    "PigmentDamageInitiatedFrom"
                                  ]
                                }
                                updateParentState={this.updateFormData([
                                  "Pigmentation",
                                  "PigmentDamageInitiatedFrom",
                                ])}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="treatmentDetail-block treatmentDetail-acne">
                          <h4 className="app-heading-medium">Acne</h4>
                          <div className="w-100">
                            <Form.Group className="form-group-app">
                              <Form.Label className="block-label">
                                Grade
                              </Form.Label>
                              <CreatableMulti
                                options={formOptions.acne}
                                isMulti={false}
                                selected={formData["Acne"]["grade"]}
                                updateParentState={this.updateFormData([
                                  "Acne",
                                  "grade",
                                ])}
                              />
                            </Form.Group>
                          </div>
                          <h6 className="block-label font-weight-bold">
                            Lesions Count
                          </h6>
                          <div className="row row-space-ten">
                            <div className="col-12 col-sm-6">
                              <Form.Group className="form-group-app">
                                <Form.Label className="block-label">
                                  Left Side
                                </Form.Label>
                                <Form.Control
                                  value={
                                    formData["Acne"]["lesionsCount"]["leftSide"]
                                  }
                                  onChange={(e) =>
                                    this.updateFormData([
                                      "Acne",
                                      "lesionsCount",
                                      "leftSide",
                                    ])(e.target.value)
                                  }
                                  type="text"
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                              <Form.Group className="form-group-app">
                                <Form.Label className="block-label">
                                  Right Side
                                </Form.Label>
                                <Form.Control
                                  value={
                                    formData["Acne"]["lesionsCount"][
                                      "rightSide"
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.updateFormData([
                                      "Acne",
                                      "lesionsCount",
                                      "rightSide",
                                    ])(e.target.value)
                                  }
                                  type="text"
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <h6 className="block-label font-weight-bold">
                            Lesions Identified
                          </h6>
                          <div className="row row-space-ten">
                            <div className="col-12 col-sm-6">
                              <Form.Group className="form-group-app">
                                <Form.Label className="block-label">
                                  Open Comedones
                                </Form.Label>
                                <Form.Control
                                  value={
                                    formData["Acne"]["LesionsIdentified"][
                                      "openComedones"
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.updateFormData([
                                      "Acne",
                                      "LesionsIdentified",
                                      "openComedones",
                                    ])(e.target.value)
                                  }
                                  type="text"
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                              <Form.Group className="form-group-app">
                                <Form.Label className="block-label">
                                  Papules
                                </Form.Label>
                                <Form.Control
                                  value={
                                    formData["Acne"]["LesionsIdentified"][
                                      "openPapules"
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.updateFormData([
                                      "Acne",
                                      "LesionsIdentified",
                                      "openPapules",
                                    ])(e.target.value)
                                  }
                                  type="text"
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row row-space-ten">
                            <div className="col-12 col-sm-6">
                              <Form.Group className="form-group-app">
                                <Form.Label className="block-label">
                                  Closed Comedones
                                </Form.Label>
                                <Form.Control
                                  value={
                                    formData["Acne"]["LesionsIdentified"][
                                      "closedComedones"
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.updateFormData([
                                      "Acne",
                                      "LesionsIdentified",
                                      "closedComedones",
                                    ])(e.target.value)
                                  }
                                  type="text"
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                              <Form.Group className="form-group-app">
                                <Form.Label className="block-label">
                                  Pustules
                                </Form.Label>
                                <Form.Control
                                  value={
                                    formData["Acne"]["LesionsIdentified"][
                                      "closedPapules"
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.updateFormData([
                                      "Acne",
                                      "LesionsIdentified",
                                      "closedPapules",
                                    ])(e.target.value)
                                  }
                                  type="text"
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row row-space-ten">
                            <div className="col-12 col-sm-6">
                              <Form.Group className="form-group-app">
                                <Form.Label className="block-label">
                                  Nodules
                                </Form.Label>
                                <Form.Control
                                  value={
                                    formData["Acne"]["LesionsIdentified"][
                                      "nodules"
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.updateFormData([
                                      "Acne",
                                      "LesionsIdentified",
                                      "nodules",
                                    ])(e.target.value)
                                  }
                                  type="text"
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-6">
                              <Form.Group className="form-group-app">
                                <Form.Label className="block-label">
                                  Cysts
                                </Form.Label>
                                <Form.Control
                                  value={
                                    formData["Acne"]["LesionsIdentified"][
                                      "cysts"
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.updateFormData([
                                      "Acne",
                                      "LesionsIdentified",
                                      "cysts",
                                    ])(e.target.value)
                                  }
                                  type="text"
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row row-space-ten">
                            <div className="col-12 col-sm-6">
                              <Form.Group className="form-group-app">
                                <Form.Label className="block-label">
                                  Milia
                                </Form.Label>
                                <Form.Control
                                  value={
                                    formData["Acne"]["LesionsIdentified"][
                                      "milia"
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.updateFormData([
                                      "Acne",
                                      "LesionsIdentified",
                                      "milia",
                                    ])(e.target.value)
                                  }
                                  type="text"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-7 col-xl-8">
                        <div className="treatment-edit-box position-relative">
                          <div className="treatment-edit-img">
                            {this.props?.formDetails?.faceCharting !== "" ? (
                              <CanvasDraw
                                onChange={(pointer) => {
                                  this.updateFormData(["faceCharting"])(
                                    pointer.getSaveData()
                                  );
                                }}
                                ref={this.setRef}
                                saveData={this.props?.formDetails?.faceCharting}
                                canvasWidth={280}
                                canvasHeight={280}
                                lazyRadius={0}
                                brushRadius={
                                  this?.brushSizesAvailable[
                                    this?.state?.brushSize
                                  ]?.size
                                }
                                imgSrc="/images/treatment-img.png"
                                brushColor={
                                  this.props.formOptions.faceChartingOptions &&
                                  this.props.formOptions.faceChartingOptions
                                    .length &&
                                  this.props.formOptions.faceChartingOptions[
                                    brushColor
                                  ].color
                                }
                              />
                            ) : (
                                <CanvasDraw
                                  onChange={(pointer) => {
                                    this.updateFormData(["faceCharting"])(
                                      pointer.getSaveData()
                                    );
                                  }}
                                  canvasWidth={280}
                                  canvasHeight={280}
                                  lazyRadius={0}
                                  brushRadius={
                                    this?.brushSizesAvailable[
                                      this?.state?.brushSize
                                    ]?.size
                                  }
                                  ref={this.setRef}
                                  imgSrc="/images/treatment-img.png"
                                  brushColor={
                                    this.props.formOptions.faceChartingOptions &&
                                    this.props.formOptions.faceChartingOptions
                                      .length &&
                                    this.props.formOptions.faceChartingOptions[
                                      brushColor
                                    ].color
                                  }
                                />
                              )}
                          </div>
                          <a
                            className="digit-box bg-primary"
                            onClick={() => {
                              if (this.canvasRef) {
                                this.canvasRef.clear();
                                this.updateFormData(["faceCharting"])(
                                  JSON.stringify({
                                    lines: [],
                                    width: 280,
                                    height: 280,
                                  })
                                );
                              }
                            }}
                          >
                            <i className="icon-cross"></i>
                          </a>
                          <div className="d-flex flex-column align-items-center">
                            <div className="treatment-edit-options d-inline-flex">
                              {this.renderCanvasOptions()}
                            </div>
                            <div className="treatment-edit-options treatment-size-option d-inline-flex mt-4">
                              {this.renderBrushOptions()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="treatmentDetail-block">
                      <h4 className="app-heading-medium">Sensitivity:</h4>
                      <div className="row row-space-ten">
                        <div className="col-12 col-sm-4 col-xl-4">
                          <Form.Group className="form-group-app">
                            <Form.Label className="block-label">
                              Rosacea Stage:
                            </Form.Label>
                            <CreatableMulti
                              options={formOptions.rosacea}
                              selected={formData["Sensitivity"]["rosaceaStage"]}
                              updateParentState={this.updateFormData([
                                "Sensitivity",
                                "rosaceaStage",
                              ])}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-12 col-sm-4 col-xl-4">
                          <Form.Group className="form-group-app">
                            <Form.Label className="block-label">
                              Telangiectasia
                            </Form.Label>
                            <CreatableMulti
                              options={formOptions.telangiectasia}
                              selected={formData["Sensitivity"]["skinType"]}
                              isMulti={false}
                              updateParentState={this.updateFormData([
                                "Sensitivity",
                                "skinType",
                              ])}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-12 col-sm-4 col-xl-4">
                          <Form.Group className="form-group-app">
                            <Form.Label className="block-label">
                              Location
                            </Form.Label>
                            <CreatableMulti
                              options={formOptions.location}
                              selected={formData["Sensitivity"]["location"]}
                              updateParentState={this.updateFormData([
                                "Sensitivity",
                                "location",
                              ])}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="st-block d-flex flex-wrap">
                        <div className="st-block-inner">
                          <h6 className="block-label">
                            Hyper Sensitive to touch
                          </h6>
                          <ul className="treatmentDetail-list">
                            <li>
                              <a
                                className={
                                  formData["Sensitivity"]["hyperSensitive"]
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  this.updateFormData([
                                    "Sensitivity",
                                    "hyperSensitive",
                                  ])(true)
                                }
                              >
                                Yes
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  formData["Sensitivity"]["hyperSensitive"]
                                    ? ""
                                    : "active"
                                }
                                onClick={() =>
                                  this.updateFormData([
                                    "Sensitivity",
                                    "hyperSensitive",
                                  ])(false)
                                }
                              >
                                No
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div className="st-block-inner">
                          <h6 className="block-label">Skin Visibly Peeling</h6>
                          <ul className="treatmentDetail-list">
                            <li>
                              <a
                                className={
                                  formData["Sensitivity"]["skinVisiblePeeling"]
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  this.updateFormData([
                                    "Sensitivity",
                                    "skinVisiblePeeling",
                                  ])(true)
                                }
                              >
                                Yes
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  formData["Sensitivity"]["skinVisiblePeeling"]
                                    ? ""
                                    : "active"
                                }
                                onClick={() =>
                                  this.updateFormData([
                                    "Sensitivity",
                                    "skinVisiblePeeling",
                                  ])(false)
                                }
                              >
                                No
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="st-block-inner">
                          <h6 className="block-label">Open wounds</h6>
                          <ul className="treatmentDetail-list">
                            <li>
                              <a
                                className={
                                  formData["Sensitivity"]["openWounds"]
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  this.updateFormData([
                                    "Sensitivity",
                                    "openWounds",
                                  ])(true)
                                }
                              >
                                Yes
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  formData["Sensitivity"]["openWounds"]
                                    ? ""
                                    : "active"
                                }
                                onClick={() =>
                                  this.updateFormData([
                                    "Sensitivity",
                                    "openWounds",
                                  ])(false)
                                }
                              >
                                No
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="row row-space-ten">
                      <div className="col-12">
                        <div className="treatmentDetail-block mb-2">
                          <h4 className="app-heading-medium mb-0">Notes</h4>
                          <Form.Group className="form-group-app fg-app-area">
                            <Form.Control
                              value={this.props.username != "shannonboddie" ? fillText : formData.notes}
                              onChange={(e) =>
                                this.updateFormData(["notes"])(e.target.value)
                              }
                              as="textarea"
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="treatmentDetail-block mb-2">
                          <h4 className="app-heading-medium mb-0">
                            Samples Given
                          </h4>
                          <Form.Group className="form-group-app fg-app-area">
                            <Form.Control
                              value={this.props.username != "shannonboddie" ? fillText : formData.samplesGiven}
                              onChange={(e) =>
                                this.updateFormData(["samplesGiven"])(
                                  e.target.value
                                )
                              }
                              as="textarea"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="treatmentDetail-block row">
                      <div className="col-12 col-lg-5 col-xl-3">
                        <Form.Group className="form-group-app">
                          <Form.Label className="block-label">
                            <h4 className="app-heading-medium mb-1 mt-1">
                              Products Purchased
                            </h4>
                          </Form.Label>
                          <CreatableMulti
                            options={formOptions.products}
                            selected={formData["productsPurchased"]}
                            updateParentState={this.updateFormData([
                              "productsPurchased",
                            ])}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row row-space-ten">
                      <div className="col-12">
                        <div className="treatmentDetail-block mb-2">
                          <h4 className="app-heading-medium mb-0">
                            Progress Notes / Soap Charting
                          </h4>
                          <Form.Group className="form-group-app fg-app-area">
                            <Form.Control
                              value={formData.ProgressNotes}
                              onChange={(e) =>
                                this.updateFormData(["ProgressNotes"])(
                                  e.target.value
                                )
                              }
                              as="textarea"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="treatmentDetail-block">
                      <h4 className="app-heading-medium mb-0">Objective</h4>{" "}
                      {/* *****Will be modified once redux is setup */}
                      <div className="treatmentDetail-objectives">
                        <strong>Fitzpatrick:</strong>{" "}
                        {formData?.fitzpatrickType?.label
                          ? formData?.fitzpatrickType?.label
                          : "Not Selected"}
                        , <strong>Glogau Classification:</strong>{" "}
                        {formData?.glogauClassification?.label
                          ? formData?.glogauClassification?.label
                          : "Not Selected"}
                        , <strong>Skin Type:</strong>{" "}
                        {formData?.skinType?.label
                          ? formData?.skinType?.label
                          : "Not Selected"}
                        , <strong>Sub-Dermis Thickness:</strong>{" "}
                        {formData?.SubDermisThickness},{" "}
                        <strong>Patch Test Performed:</strong>{" "}
                        {formData?.PatchTestPerformed ? "Yes" : "No"},{" "}
                        <strong>Wrinkle Assessment:</strong>{" "}
                        {formData?.FineLinesAndWrinkles?.wrinkleAssessment
                          ?.label
                          ? formData?.FineLinesAndWrinkles?.wrinkleAssessment
                            ?.label
                          : "Not Selected"}
                        , <strong>Elastosis: </strong>{" "}
                        {formData?.FineLinesAndWrinkles?.elastosisAssessment
                          ?.label
                          ? formData?.FineLinesAndWrinkles?.elastosisAssessment
                            ?.label
                          : "Not Selected"}
                        , <strong>Photo Damage:</strong>{" "}
                        {formData?.Pigmentation?.PhotoDamage?.label
                          ? formData?.Pigmentation?.PhotoDamage?.label
                          : "Not Selected"}
                        , <strong>Pigment Damage Initiated From:</strong>{" "}
                        {formData?.Pigmentation?.PigmentDamageInitiatedFrom
                          ?.label
                          ? formData?.Pigmentation?.PigmentDamageInitiatedFrom
                            ?.label
                          : "Not Selected"}
                        , <strong>Acne Grade:</strong>{" "}
                        {formData?.Acne?.grade?.label
                          ? formData?.Acne?.grade?.label
                          : "Not Selected"}
                        , <strong>Lessions Count Right Side:</strong>{" "}
                        {formData?.Acne?.lesionsCount?.leftSide},{" "}
                        <strong>Lessions Count Right Side:</strong>{" "}
                        {formData?.Acne?.lesionsCount?.rightSide},{" "}
                        <strong>Open Comedones:</strong>{" "}
                        {formData?.Acne?.LesionsIdentified?.openComedones},{" "}
                        <strong>Papules:</strong>{" "}
                        {formData?.Acne?.LesionsIdentified?.openPapules},{" "}
                        <strong>Closed Comedones:</strong>{" "}
                        {formData?.Acne?.LesionsIdentified?.closedComedones},{" "}
                        <strong>Pustules:</strong>{" "}
                        {formData?.Acne?.LesionsIdentified?.closedPapules},{" "}
                        <strong>Nodules:</strong>{" "}
                        {formData?.Acne?.LesionsIdentified?.nodules},{" "}
                        <strong>Cysts:</strong>{" "}
                        {formData?.Acne?.LesionsIdentified?.cysts},{" "}
                        <strong>Rosacea Stage:</strong>{" "}
                        {formData?.Sensitivity?.rosaceaStage?.length
                          ? formData?.Sensitivity?.rosaceaStage.map(
                            (obj) => obj.label + ", "
                          )
                          : "Not Selected,"}{" "}
                        <strong>Telangiectasia:</strong>{" "}
                        {formData?.Sensitivity?.skinType?.label
                          ? formData?.Sensitivity?.skinType?.label
                          : "Not Selected"}
                        , <strong>Location:</strong>{" "}
                        {formData?.Sensitivity?.location?.length
                          ? formData?.Sensitivity?.location.map(
                            (obj) => obj.label + ", "
                          )
                          : "Not Selected,"}{" "}
                        <strong>Hyper Sensitive To Touch:</strong>{" "}
                        {formData?.Sensitivity?.hyperSensitive ? "Yes" : "No"},{" "}
                        <strong>Skin Visibly Peeling:</strong>{" "}
                        {formData?.Sensitivity?.skinVisiblePeeling
                          ? "Yes"
                          : "No"}
                        , <strong>Open Wounds:</strong>{" "}
                        {formData?.Sensitivity?.openWounds ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="treatmentDetail-block">
                      <div className="row row-space-ten">
                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                          <Form.Group className="form-group-app">
                            <Form.Label className="block-label">
                              <h4 className="app-heading-medium mb-1">
                                Assessment
                              </h4>
                            </Form.Label>
                            <CreatableMulti
                              options={formOptions.treatment}
                              selected={formData["assessment"]}
                              updateParentState={this.updateFormData([
                                "assessment",
                              ])}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                          <Form.Group className="form-group-app">
                            <Form.Label className="block-label">
                              <h4 className="app-heading-medium mb-1">Plan</h4>
                            </Form.Label>
                            <CreatableMulti
                              options={formOptions.products}
                              selected={formData["plan"]}
                              updateParentState={this.updateFormData(["plan"])}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div
                      className="treatmentDetail-block"
                      style={{ display: "inline-block", fontSize: "16px" }}
                    >
                      <div className="checkbox-row">
                        <label className="checkbox-box">
                          Verbal consent obtained
                          <input
                            type="checkbox"
                            checked={formData["isVerbalConsent"]}
                            onChange={(e) => {
                              this.updateFormData(["isVerbalConsent"])(
                                !formData["isVerbalConsent"]
                              );
                            }}
                            style={{
                              color: "white",
                              width: "14px",
                              height: "14px",
                            }}
                          />{" "}
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="checkbox-row">
                        <label className="checkbox-box">
                          Written Consent Signed Prior To Treatment
                          <input
                            type="checkbox"
                            checked={formData["isWrittenConsent"]}
                            onChange={(e) => {
                              this.updateFormData(["isWrittenConsent"])(
                                !formData["isWrittenConsent"]
                              );
                            }}
                            style={{
                              color: "white",
                              width: "14px",
                              height: "14px",
                            }}
                          />{" "}
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="checkbox-row">
                        <label className="checkbox-box">
                          After Care Instructions Explained Verbally
                          <input
                            type="checkbox"
                            checked={formData["isVerbalInstructions"]}
                            onChange={(e) => {
                              this.updateFormData(["isVerbalInstructions"])(
                                !formData["isVerbalInstructions"]
                              );
                            }}
                            style={{
                              color: "white",
                              width: "14px",
                              height: "14px",
                            }}
                          />{" "}
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="checkbox-row">
                        <label className="checkbox-box">
                          Written Aftercare Instructions Given To Client
                          <input
                            type="checkbox"
                            checked={formData["isWrittenInstructions"]}
                            onChange={(e) => {
                              this.updateFormData(["isWrittenInstructions"])(
                                !formData["isWrittenInstructions"]
                              );
                            }}
                            style={{
                              color: "white",
                              width: "14px",
                              height: "14px",
                            }}
                          />{" "}
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="treatmentDetail-block">
                      <div
                        style={{ paddingLeft: "0px" }}
                        className="col-12 col-sm-4 col-lg-3"
                      >
                        <h4 className="app-heading-medium mb-0">
                          Signature and Date
                        </h4>
                        <div className="treatmentDetail-objectives">
                          <strong>Signed On: </strong>
                          {this.props.formDetails?.signatureAndDate
                            ?.signature && formData?.signatureAndDate?.date
                            ? formData.signatureAndDate.date
                            : "N/A"}
                        </div>
                        <Form.Group className="form-group-app fg-app-field fg-field-family">
                          <Form.Control
                            value={formData.signatureAndDate.signature}
                            onChange={(e) =>
                              this.updateFormData([
                                "signatureAndDate",
                                "signature",
                              ])(e.target.value)
                            }
                            type="text"
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="pda-btns-canvas">
              <button
                disabled={!this.state.validDate || saveFormLoading}
                className="save-button px-4"
                onClick={this.saveFormData}
              >
                {saveFormLoading ? 'Saving' : 'Save'}
                {saveFormLoading && <SmallLoader />}
              </button>
              <button
                className="save-button px-4"
                onClick={(e) => {
                  e.preventDefault();
                  this.printDocument();
                }}
                disabled={printLoading}
              >
                {printLoading ? 'Saving' : this.state.dirty ? 'Save and Print Form' : 'Print Form'}
                {printLoading && <SmallLoader />}
              </button>
              <div className="email-form">
                <button
                  className="save-button px-4"
                  onClick={async (e) => {
                    e.preventDefault();
                    let formDataSaved = true;
                    if (this.state.dirty) {
                      formDataSaved = await this.saveFormData(false, false);
                    }
                    if (formDataSaved) {
                      this.setState({ showEmailModal: true });
                    }
                  }}
                >
                  {this.state.dirty ? "Save and Send Form as Email" : "Send Form as Email"}
                </button>
                <EmailModal
                  showEmailModal={showEmailModal}
                  handleClose={() => { this.setState({ showEmailModal: false }) }}
                  emailData={{
                    formId: this.props?.match?.params?.formId,
                    customerName: customer.name,
                    formName: 'PDA'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);

PDATreatmentForm.propTypes = {
  customer: PropTypes.object,
  formDetails: PropTypes.object,
  formOptions: PropTypes.object,
};

PDATreatmentForm.defaultProps = {
  customer: {
    name: "Not Random Name",
    dob: "Random DOB",
    email: "Random Email",
    phone: "Random Phone",
    address: "Random Address",
    imageUrl: "Random URL",
    allergy: "Random Allergy",
    id: "Random id",
  },
  formOptions: {},
  formDetails: {
    date: null,
    treatmentPerformed: [],
    priorCosmeticProcedures: "",
    fitzpatrickType: "",
    glogauClassification: "",
    skinType: "",
    conditions: [],
    faceCharting: "",
    SubDermisThickness: "Thin",
    PatchTestPerformed: false,
    FineLinesAndWrinkles: {
      wrinkleAssessment: "",
      elastosisAssessment: "",
    },
    Pigmentation: {
      PhotoDamage: "",
      PigmentDamageInitiatedFrom: "",
    },
    Acne: {
      grade: "",
      lesionsCount: {
        leftSide: "",
        rightSide: "",
      },
      LesionsIdentified: {
        openComedones: "",
        openPapules: "",
        closedComedones: "",
        closedPapules: "",
        nodules: "",
        cysts: "",
        milia: "",
      },
    },
    Sensitivity: {
      rosaceaStage: [],
      skinType: [],
      location: [],
      hyperSensitive: false,
      skinVisiblePeeling: false,
      openWounds: false,
    },
    notes: "",
    samplesGiven: "",
    productsPurchased: [],
    ProgressNotes: "",
    assessment: [],
    plan: [],
    isVerbalConsent: false,
    isWrittenConsent: false,
    isVerbalInstructions: false,
    isWrittenInstructions: false,
    signatureAndDate: {
      signature: "",
      date: "",
    },
  },
};

export default withRouter(PDATreatmentForm);
