import { cloneDeep } from "lodash";
import { showNotification } from "../../Utils/misc.utils";

const initialState = {
  loading: false,
  err: null,
  options: {
    treatment: [],
    wrinkles: [
      { value: "Mild", label: "Mild" },
      { value: "Average", label: "Average" },
      { value: "Moderate", label: "Moderate" },
      { value: "Severe", label: "Severe" }
    ],
    elastosis: [
      { value: "Mild", label: "Mild" },
      { value: "Average", label: "Average" },
      { value: "Moderate", label: "Moderate" },
      { value: "Severe", label: "Severe" }
    ],
    photo: [
      { value: "Mild", label: "Mild" },
      { value: "Average", label: "Average" },
      { value: "Moderate", label: "Moderate" },
      { value: "Severe", label: "Severe" }
    ],
    pigment: [],
    skinCareSpecialists: [],
    skinType: [
      { value: "Normal", label: "Normal" },
      { value: "Dry", label: "Dry" },
      { value: "Combo", label: "Combo" },
      { value: "Oily", label: "Oily" },
      { value: "Sensitive/Reactive", label: "Sensitive/Reactive" }
    ],
    conditions: [],
    fitzpatrick: [
      { value: "I", label: "I" },
      { value: "II", label: "II" },
      { value: "III", label: "III" },
      { value: "IV", label: "IV" },
      { value: "V", label: "V" },
      { value: "VI", label: "VI" }
    ],
    glogauClassification: [
      { value: "I", label: "I" },
      { value: "II", label: "II" },
      { value: "III", label: "III" },
      { value: "IV", label: "IV" }
    ],
    acne: [
      { value: "I", label: "I" },
      { value: "II", label: "II" },
      { value: "III", label: "III" },
      { value: "IV", label: "IV" }
    ],
    rosacea: [
      { value: "I", label: "I" },
      { value: "II", label: "II" },
      { value: "III", label: "III" },
      { value: "IV", label: "IV" },
      { value: "NA", label: "NA" }
    ],
    telangiectasia: [
      { value: "Mild", label: "Mild" },
      { value: "Average", label: "Average" },
      { value: "Moderate", label: "Moderate" },
      { value: "Severe", label: "Severe" }
    ],
    location: [
      { value: "Glabella", label: "Glabella" },
      { value: "Temple(s)", label: "Temple(s)" },
      { value: "Hairline", label: "Hairline" },
      { value: "Cheek(s)", label: "Cheek(s)" },
      { value: "Nose", label: "Nose" },
      { value: "Chin/Perioral", label: "Chin/Perioral" },
      { value: "Neck", label: "Neck" },
      { value: "Jawline", label: "Jawline" },
      { value: "Eye(s)/Periorbital", label: "Eye(s)/Periorbital" },
      { value: "Décolletage", label: "Décolletage" }
    ],
    faceChartingOptions: [],
    products: []
    // ***** Add color options for image canvas *****
  }
};

const availableColors = [
  "#FF0000",
  "#800000",
  "#FFFF00",
  "#808000",
  "#00FF00",
  "#008000",
  "#00FFFF",
  "#008080",
  "#0000FF",
  "#FF00FF",
  "#800080"
];

const FormOptions = (state = initialState, action) => {
  if (action.type === "LOADING_FORM_OPTIONS") {
    return { loading: true, err: null, options: { ...state.options } };
  }
  if (action.type === "LOADING_FORM_OPTIONS_SUCCESSFUL") {
    const { payload } = action;
    const stateCopy = cloneDeep(state);
    payload.options.faceChartingOptions.map(opt => {
      // ***** Should I add includes check?
      if (availableColors.indexOf(opt.color) !== -1) {
        availableColors.splice(availableColors.indexOf(opt.color), 1);
      }
    });
    return {
      options: { ...state.options, ...payload.options },
      loading: false,
      err: null
    };
  }
  if (action.type === "LOADING_FORM_OPTIONS_UNSUCCESSFUL") {
    const { payload } = action;
    const initialStateClone = cloneDeep(initialState);
    return {
      loading: false,
      err: payload.err,
      options: { ...initialStateClone.options }
    };
  }
  if (action.type === "ADD_FORM_OPTION") {
    // ****** WILL BE UPDATED ONCE SETTINGS PAGE IS CREATED
    const { payload } = action;
    const stateCopy = cloneDeep(state);
    const valueToAdd = payload.values[0];
    if (payload.option === "faceChartingOptions") {
      const fcColor = availableColors.pop();
      if (fcColor) {
        stateCopy["options"][payload.option] = [
          ...stateCopy["options"][payload.option],
          {
            value: valueToAdd,
            label: valueToAdd,
            color: fcColor
          }
        ];
      } else {
        showNotification(
          "error",
          "Limit Reached",
          "Cannot add more face charting options"
        );
      }
      return stateCopy;
    }
    stateCopy["options"][payload.option] = [
      ...stateCopy["options"][payload.option],
      {
        value: valueToAdd,
        label: valueToAdd,
        ...(payload.color && { color: payload.color })
      }
    ];
    return stateCopy;
  }
  if (action.type === "DELETE_FORM_OPTION") {
    const { payload } = action;
    const stateCopy = cloneDeep(state);
    const valueToRemove = payload.values[0];
    stateCopy["options"][payload.option] = stateCopy["options"][
      payload.option
    ].filter(elem => {
      if (
        elem.label === valueToRemove &&
        payload.option === "faceChartingOptions"
      ) {
        availableColors.push(elem.color);
      }
      return elem.label !== valueToRemove;
    });
    return stateCopy;
  }

  if (action.type === "CLEAR_FORM_OPTIONS") {
    return cloneDeep(initialState);
  }
  return state;
};

export default FormOptions;
