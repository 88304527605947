import React from 'react';

const SmallLoader = ({ className }) => {
  return (
    <div className={className ? `lds-spinner ${className}` : 'lds-spinner'}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SmallLoader;
